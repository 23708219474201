import { Supplier } from "types/src/Supplier/Supplier";
import { silentUnreachableError } from "utils/exceptions";
import { FieldId, FieldType } from "types/src/DataType/FieldType";
import { Field } from "types/src/DataType/Field";
import { strictGuard } from "utils/strictGuard";
import * as SchemaFields from "../../../../../../../../../generic-states/SchemaFields";

// region Actions
export type Actions =
  | LoadSuccess
  | LoadFail
  | Submit
  | SaveError
  | SaveSuccess
  | SchemaFields.Actions;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (SchemaFields.isActions(a)) return true;

  switch (a.type) {
    case "Ready:DataManager:Suppliers:Edit:LoadFail":
    case "Ready:DataManager:Suppliers:Edit:LoadSuccess":
    case "Ready:DataManager:Suppliers:Edit:SaveError":
    case "Ready:DataManager:Suppliers:Edit:SaveSuccess":
    case "Ready:DataManager:Suppliers:Edit:Submit":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:Suppliers:Edit:LoadFail";
}

export const loadFail = (): LoadFail => ({
  type: "Ready:DataManager:Suppliers:Edit:LoadFail",
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:Suppliers:Edit:LoadSuccess";
  payload: {
    fields: Record<FieldId, Field<FieldType>>;
    item: Supplier;
  };
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:Suppliers:Edit:LoadSuccess",
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: "Ready:DataManager:Suppliers:Edit:Submit";
}

export const submit = (): Submit => ({
  type: "Ready:DataManager:Suppliers:Edit:Submit",
});
// endregion

// region SaveError
export interface SaveError {
  type: "Ready:DataManager:Suppliers:Edit:SaveError";
}

export const saveError = (): SaveError => ({
  type: "Ready:DataManager:Suppliers:Edit:SaveError",
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: "Ready:DataManager:Suppliers:Edit:SaveSuccess";
  payload: Supplier;
}

export const saveSuccess = (payload: SaveSuccess["payload"]): SaveSuccess => ({
  type: "Ready:DataManager:Suppliers:Edit:SaveSuccess",
  payload,
});
// endregion
