import { ReactElement, useCallback, useMemo } from "react";
import * as Listing from "state-manager/states/Ready/states/DataManager/states/Customers/states/Listing";
import { RootState } from "state-manager";
import { flow } from "fp-ts/function";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import * as Arr from "fp-ts/Array";
import { Field } from "types/src/DataType/Field";
import { FieldType } from "types/src/DataType/FieldType";
import { TranslatedStr } from "types/src/TranslatedStr";
import { ListingHeader } from "./ListingHeader";
import { DeleteConfirmation } from "./DeleteConfirmation";
import { CustomersTable, CustomersTableProps } from "./CustomersTable";

export interface AllCustomersProps {
  selector$: (s: RootState) => Listing.Ready | Listing.Fetching;
  dispatch: (a: Listing.Actions) => void;
}

export function AllCustomers({
  selector$,
  dispatch,
}: AllCustomersProps): ReactElement {
  const columns$ = useMemo(
    () =>
      flow(
        selector$,
        (s) => s.payload.dataType.schema.fields,
        (vs) => Object.values(vs),
        Arr.sortBy<Field<FieldType>>([
          {
            equals: (a, b) => a.id === b.id,
            compare: (a, b) => (a.order > b.order ? 1 : -1),
          },
        ]),
        (vs) =>
          vs.map((v) => ({
            id: v.id,
            label: v.label as TranslatedStr,
            type: v.type,
          })),
      ),
    [selector$],
  );
  const items$: CustomersTableProps["items$"] = useMemo(
    () => flow(selector$, (s) => s.payload.items),
    [selector$],
  );
  const orderBy$ = useMemo(
    () => flow(selector$, (s) => s.payload.order),
    [selector$],
  );

  const orderBy = useCallback(
    (v: "createdAt" | "updatedAt") => dispatch(Listing.orderBy(v)),
    [dispatch],
  );
  const onSelect = useCallback(flow(Listing.select, dispatch), [dispatch]);
  const onSelectAll = useCallback(flow(Listing.selectAll, dispatch), [
    dispatch,
  ]);

  const onDelete = useCallback(flow(Listing.removeItem, dispatch), [dispatch]);
  const onDeleteAll = useCallback(flow(Listing.removeBulk, dispatch), [
    dispatch,
  ]);

  return (
    <>
      <ListingWrapper
        header={<ListingHeader selector$={selector$} dispatch={dispatch} />}
      >
        <CustomersTable
          columns$={columns$}
          items$={items$}
          orderBy$={orderBy$}
          orderBy={orderBy}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          onDeleteAll={onDeleteAll}
          onDelete={onDelete}
        />
      </ListingWrapper>
      <DeleteConfirmation selector$={selector$} dispatch={dispatch} />
    </>
  );
}
