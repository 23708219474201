import { Selector, useSelector } from "state-manager";
import * as Create from "state-manager/states/Ready/states/DataManager/states/Customers/states/Create";
import * as Actions from "state-manager/generic-states/SchemaFields";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "@Pages/Loading";
import { SchemaFields, SchemaFieldsProps } from "@Containers/SchemaFields";

export interface ContentProps {
  selector: Selector<Create.State>;
  dispatch: (a: Create.Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const formSelector = useSelector(
    flow(selector, (s) => {
      switch (s.type) {
        case "Ready:DataManager:Customers:Create:Loading":
        case "Ready:DataManager:Customers:Create:LoadError":
          return {
            type: "loading",
          } as const;
        case "Ready:DataManager:Customers:Create:Ready":
        case "Ready:DataManager:Customers:Create:Saving": {
          const props: SchemaFieldsProps = {
            selector: flow(
              selector,
              (s) => s as Create.Ready,
              (s) => s.payload.schema.payload,
            ),
            onChange: (v) => {
              switch (v.type) {
                case "number":
                  dispatch(
                    Actions.setNumberValue({
                      id: v.id,
                      value: v.value as number,
                    }),
                  );
                  break;
                case "text":
                  dispatch(
                    Actions.setTextValue({
                      id: v.id,
                      value: v.value as string,
                    }),
                  );
                  break;
                default:
                  silentUnreachableError(v.type);
              }
            },
          };

          return {
            type: "ready",
            props,
          } as const;
        }

        default: {
          silentUnreachableError(s);
          return {
            type: "loading",
          } as const;
        }
      }
    }),
    (a, b) => a.type === b.type,
  );

  switch (formSelector.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <SchemaFields
          selector={formSelector.props.selector}
          onChange={formSelector.props.onChange}
        />
      );
  }
}
