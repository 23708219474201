import { silentUnreachableError, unreachableError } from "utils/exceptions";
import { User } from "types";
import { right } from "fp-ts/Either";
import * as Ready from "../Ready";

import * as DataManager from "../Ready/states/DataManager";
import * as BuilderPreview from "../Ready/states/BuilderPreview";
import * as BPMNPreview from "../Ready/states/BPMNPreview";
import * as ZitadelPreview from "../Ready/states/ZitadelPreview";
import * as SandboxPreview from "../Ready/states/SandboxPreview";
import * as DataTypesListing from "../Ready/states/DataManager/states/DataTypes/states/Listing";
import * as DataTypesEdit from "../Ready/states/DataManager/states/DataTypes/states/Edit";
import * as DataTypesCreate from "../Ready/states/DataManager/states/DataTypes/states/Create";

import * as CustomersListingAll from "../Ready/states/DataManager/states/Customers/states/ListingAll";
import * as CustomersListing from "../Ready/states/DataManager/states/Customers/states/Listing";
import * as CustomersCreate from "../Ready/states/DataManager/states/Customers/states/Create";
import * as CustomersEdit from "../Ready/states/DataManager/states/Customers/states/Edit";

import * as SuppliersListingAll from "../Ready/states/DataManager/states/Suppliers/states/ListingAll";
import * as SuppliersListing from "../Ready/states/DataManager/states/Suppliers/states/Listing";
import * as SuppliersCreate from "../Ready/states/DataManager/states/Suppliers/states/Create";
import * as SuppliersEdit from "../Ready/states/DataManager/states/Suppliers/states/Edit";

import * as RepositoriesListingAll from "../Ready/states/DataManager/states/Repositories/states/ListingAll";
import * as RepositoriesListing from "../Ready/states/DataManager/states/Repositories/states/Listing";
import * as RepositoriesCreate from "../Ready/states/DataManager/states/Repositories/states/Create";
import * as RepositoriesEdit from "../Ready/states/DataManager/states/Repositories/states/Edit";

import * as InventoryItemsListingAll from "../Ready/states/DataManager/states/InventoryItems/states/ListingAll";
import * as InventoryItemsListing from "../Ready/states/DataManager/states/InventoryItems/states/Listing";
import * as InventoryItemsCreate from "../Ready/states/DataManager/states/InventoryItems/states/Create";
import * as InventoryItemsEdit from "../Ready/states/DataManager/states/InventoryItems/states/Edit";

import * as ItemMovementsListingAll from "../Ready/states/DataManager/states/ItemMovements/states/ListingAll";
import * as ItemMovementsListing from "../Ready/states/DataManager/states/ItemMovements/states/Listing";
import * as ItemMovementsCreate from "../Ready/states/DataManager/states/ItemMovements/states/Create";
import * as ItemMovementsEdit from "../Ready/states/DataManager/states/ItemMovements/states/Edit";

import * as RepositoryMovementsListingAll from "../Ready/states/DataManager/states/RepositoryMovements/states/ListingAll";
import * as RepositoryMovementsListing from "../Ready/states/DataManager/states/RepositoryMovements/states/Listing";
import * as RepositoryMovementsCreate from "../Ready/states/DataManager/states/RepositoryMovements/states/Create";
import * as RepositoryMovementsEdit from "../Ready/states/DataManager/states/RepositoryMovements/states/Edit";

import * as StocksListing from "../Ready/states/DataManager/states/Stocks/states/Listing";
import * as StocksSingle from "../Ready/states/DataManager/states/Stocks/states/Single";

import * as TransactionsListing from "../Ready/states/DataManager/states/Transactions/states/Listing";
import * as TransactionsSingle from "../Ready/states/DataManager/states/Transactions/states/Single";

import { state } from "../Ready";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): State.State | Ready.State {
  switch (a.type) {
    case "Auth:UserPreloadFail":
      return s.type === "Auth:Idle" ? State.login(s.payload) : s;
    case "Auth:UserPreloadSuccess":
      if (s.type === "Auth:Idle") {
        return goToReducer(s, s.payload.goTo, a.payload);
      }
      return s;
    case "Auth:SignIn":
      return s.type === "Auth:Login" || s.type === "Auth:AuthError"
        ? State.authentication(s.payload)
        : s;
    case "Auth:SignInFail":
      return s.type === "Auth:Authentication" ? State.authError(s.payload) : s;
    case "Auth:SignInSuccess": {
      if (s.type === "Auth:Authentication") {
        return goToReducer(s, s.payload.goTo, a.payload);
      }
      return s;
    }
    default:
      unreachableError(a);
      return s;
  }
}

function goToReducer(
  s: State.State,
  a: State.IdlePayload["goTo"],
  _user: User,
): State.State | Ready.State {
  const { orgs, orgId, openAI, ...user } = _user;
  switch (a.type) {
    case "data-types:listing":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: DataTypesListing.init(),
        }),
      });
    case "data-types:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: DataTypesCreate.init(),
        }),
      });
    case "data-types:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: DataTypesEdit.init({ id: a.id }),
        }),
      });
    case "customers":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: CustomersListingAll.init(),
        }),
      });
    case "customers:data-type":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: CustomersListing.init({ dataTypeId: a.id, openAI }),
        }),
      });
    case "customers:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: CustomersCreate.init({
            dataTypeId: a.dataTypeId,
          }),
        }),
      });
    case "customers:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: CustomersEdit.init({
            customerId: a.id,
          }),
        }),
      });
    case "suppliers":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: SuppliersListingAll.init(),
        }),
      });
    case "suppliers:data-type":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: SuppliersListing.init({ dataTypeId: a.id, openAI }),
        }),
      });
    case "suppliers:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: SuppliersCreate.init({
            dataTypeId: a.dataTypeId,
          }),
        }),
      });
    case "suppliers:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: SuppliersEdit.init({
            id: a.id,
          }),
        }),
      });
    case "repositories":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: RepositoriesListingAll.init(),
        }),
      });
    case "repositories:data-type":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: RepositoriesListing.init(a.id),
        }),
      });
    case "repositories:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: RepositoriesCreate.init({
            dataTypeId: a.dataTypeId,
          }),
        }),
      });
    case "repositories:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: RepositoriesEdit.init({
            id: a.id,
          }),
        }),
      });
    case "inventoryItems":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: InventoryItemsListingAll.init(),
        }),
      });
    case "inventoryItems:data-type":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: InventoryItemsListing.init({ dataTypeId: a.id, openAI }),
        }),
      });
    case "inventoryItems:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: InventoryItemsCreate.init({
            dataTypeId: a.dataTypeId,
          }),
        }),
      });
    case "inventoryItems:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: InventoryItemsEdit.init({
            id: a.id,
          }),
        }),
      });
    case "itemMovements":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: ItemMovementsListingAll.init(),
        }),
      });
    case "itemMovements:data-type":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: ItemMovementsListing.init(a.id),
        }),
      });
    case "itemMovements:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: ItemMovementsCreate.init({ dataTypeId: a.id }),
        }),
      });
    case "itemMovements:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: ItemMovementsEdit.init({
            id: a.id,
          }),
        }),
      });
    case "repositoryMovements":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: RepositoryMovementsListingAll.init(),
        }),
      });
    case "repositoryMovements:data-type":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: RepositoryMovementsListing.init(a.id),
        }),
      });
    case "repositoryMovements:create":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: RepositoryMovementsCreate.init({ dataTypeId: a.id }),
        }),
      });
    case "repositoryMovements:edit":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: RepositoryMovementsEdit.init({
            id: a.id,
          }),
        }),
      });
    case "warehouse-builder":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: BuilderPreview.loading({}),
      });
    case "bpmn-builder":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: BPMNPreview.idle({}),
      });
    case "zitadel-app":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: ZitadelPreview.idle({}),
      });
    case "graphql-sandbox":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: SandboxPreview.idle({}),
      });
    case "stocks":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: StocksListing.init(),
        }),
      });
    case "stocks:single":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: StocksSingle.loading({ id: a.id }),
        }),
      });
    case "transactions":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: TransactionsListing.init(),
        }),
      });
    case "transactions:single":
      return state({
        user: right(user),
        orgId,
        openAI,
        organizations: {
          ids: orgs.map(({ id }) => id),
          entities: Object.fromEntries(orgs.map((org) => [org.id, org])),
          isActive: false,
        },
        subState: DataManager.loading({
          subState: TransactionsSingle.loading({ id: a.id }),
        }),
      });
    default: {
      silentUnreachableError(a);
      return s;
    }
  }
}
