import { Repository } from "types/src/Repositories/Repository";
import { toDate } from "types/src/date/ISODate";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import {
  RepositoryFragmentFragment,
  RepositoryType,
} from "../generated/graphql";

export function repositoryFragmentToRepository(
  fragment: RepositoryFragmentFragment,
): Repository {
  return {
    id: fragment.id as Repository["id"],
    fields: (fragment.data ?? { fields: {} }).fields as Repository["fields"],
    createdAt: toDate(fragment.createdAt),
    updatedAt: pipe(
      fragment.updatedAt,
      O.fromNullable,
      O.map(toDate),
      O.getOrElseW(() => undefined),
    ),
    dataTypeId: fragment.dataTypeID as Repository["dataTypeId"],
    name: fragment.name as Repository["name"],
    type: fragment.type,
    isVirtual: !!fragment.virtualRepo,
    parentId: fragment.parentID as Repository["parentId"],
  };
}

export function repositoryTypeToApiResponseType(
  type: Repository["type"],
): RepositoryType {
  switch (type) {
    case "static":
      return RepositoryType.Static;
    case "dynamic":
      return RepositoryType.Dynamic;
  }
}
