import * as O from "fp-ts/Option";
import { unreachableError } from "utils/exceptions";
import * as State from "./types/State";

export { createReducer } from "./reducer";
export { epicCreator } from "./epic";
export * from "./types/State";
export * from "./types/Actions";

export function getValue<P extends string, T>(
  p: P,
  s: State.State<P, unknown, T>,
): O.Option<T> {
  if (State.isSelected(p)(s)) return O.some(s.payload.item);
  if (State.isIdle(p)(s)) return O.none;
  if (State.isSearching(p)(s)) return O.none;
  if (State.isItemSearchError(p)(s)) return O.none;

  unreachableError(s);
  return O.none;
}
