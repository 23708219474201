import { silentUnreachableError } from "utils/exceptions";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { isOneOf } from "utils/isOneOf";
import * as NoEmptyString from "types/src/NoEmptyString";
import * as Create from "../Create";
import * as DataGenerator from "../../../../../../../../generic-states/data-genetator";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import { FiltersMonoid } from "./types/Filters";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): State.State | Create.State {
  if (DataGenerator.isActions(a)) {
    if (State.isReady(s)) {
      return State.ready({
        ...s.payload,
        openAI: DataGenerator.reducer(s.payload.openAI, a),
      });
    }

    return s;
  }

  switch (a.type) {
    case "Ready:DataManager:Suppliers:Listing:LoadFail": {
      if (State.isLoading(s)) return State.loadError(s.payload);
      if (State.isFetching(s)) return State.ready(s.payload);

      return s;
    }
    case "Ready:DataManager:Suppliers:Listing:LoadSuccess": {
      if (State.isLoading(s)) {
        return State.ready({
          ...s.payload,
          total: a.payload.total,
          items: a.payload.items.map((i) => ({
            id: i.id,
            createdAt: i.createdAt,
            updatedAt: O.fromNullable(i.updatedAt),
            selected: false,
            removeState: "none",
            fields: i.fields,
          })),
          dataType: a.payload.dataType,
          pageInfo: a.payload.pageInfo,
          advancedFiltersState: "closed",
          openAI: DataGenerator.idle({
            orgKey: s.payload.openAI.payload?.orgKey,
            apiKey: s.payload.openAI.payload?.apiKey,
            fieldsSchema: a.payload.dataType.jsonSchema,
            dataTypeId: a.payload.dataType.id,
            isOpen: false,
            amount: 3,
            responseExample: `[{"field1": "dummy data", ...another_objects}]`,
            templateContext: "warehouse suppliers",
            data: {},
          }),
        });
      }

      return s;
    }
    case "Ready:DataManager:Suppliers:Listing:FetchSuccess": {
      if (State.isFetching(s))
        return State.ready({
          ...s.payload,
          total: a.payload.total,
          items: a.payload.items.map((i) => ({
            id: i.id,
            createdAt: i.createdAt,
            updatedAt: O.fromNullable(i.updatedAt),
            selected:
              s.payload.items.find((si) => si.id === i.id)?.selected ?? false,
            removeState: "none",
            fields: i.fields,
          })),
          pageInfo: a.payload.pageInfo,
        });

      return s;
    }
    case "Ready:DataManager:Suppliers:Listing:SetPage": {
      if (State.isReady(s) || State.isFetching(s))
        return State.fetching({
          ...s.payload,
          page: a.payload,
        });

      return s;
    }
    case "Ready:DataManager:Suppliers:Listing:OrderBy": {
      if (State.isReady(s) || State.isFetching(s))
        return State.fetching({
          ...s.payload,
          page: "current",
          order: O.isNone(s.payload.order)
            ? O.of({ by: a.payload, direction: "asc" })
            : pipe(
                s.payload.order,
                O.chain((o) => {
                  if (o.by === a.payload) {
                    return pipe(
                      getNextDirection(O.of(o.direction)),
                      O.map((d) => ({ ...o, direction: d })),
                    );
                  } else {
                    return pipe(
                      O.of(a.payload),
                      O.map((by) => ({ by, direction: "asc" })),
                    );
                  }
                }),
              ),
        });

      return s;
    }
    case "Ready:DataManager:Suppliers:Listing:Select": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.filter((s) => s.payload.items.some((i) => i.id === a.payload)),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                items: s.payload.items.map((i) =>
                  i.id === a.payload ? { ...i, selected: !i.selected } : i,
                ),
              },
            }) as typeof s,
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:SelectAll": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) => {
          const allSelected = s.payload.items.every((i) => i.selected);
          return {
            ...s,
            payload: {
              ...s.payload,
              items: s.payload.items.map((i) => ({
                ...i,
                selected: !allSelected,
              })),
            },
          } as typeof s;
        }),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:RemoveItem": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) => s.payload.items.some((i) => i.id === a.payload)),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.id === a.payload ? { ...i, removeState: "confirmation" } : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:RemoveBulk": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) => s.payload.items.some((i) => i.selected)),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.selected
                ? {
                    ...i,
                    removeState: "confirmation",
                  }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:RemoveConfirm": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some((i) => i.removeState === "confirmation"),
        ),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.removeState === "confirmation"
                ? {
                    ...i,
                    removeState: "removing",
                  }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:RemoveDecline": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some((i) => i.removeState === "confirmation"),
        ),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              i.removeState !== "none" ? { ...i, removeState: "none" } : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:RemoveSuccess": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some(
            (i) => i.removeState === "removing" && a.payload.includes(i.id),
          ),
        ),
        O.map((s) =>
          State.fetching({
            ...s.payload,
            page: "current",
            items: s.payload.items.filter(
              (i) =>
                !(i.removeState === "removing" && a.payload.includes(i.id)),
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:RemoveFail": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.filter((s) =>
          s.payload.items.some(
            (i) => i.removeState === "removing" && a.payload.includes(i.id),
          ),
        ),
        O.map((s) =>
          State.ready({
            ...s.payload,
            items: s.payload.items.map((i) =>
              !(i.removeState === "removing" && a.payload.includes(i.id))
                ? {
                    ...i,
                    removeState: "none",
                  }
                : i,
            ),
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:SetUpdatedAtFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              updatedAt: a.payload,
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:SetCreatedAtFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              createdAt: a.payload,
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:SetSearchFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              search: NoEmptyString.fromString(a.payload),
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:SetIdFilter": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.ready({
            ...s.payload,
            filters: {
              ...s.payload.filters,
              id: NoEmptyString.fromString(a.payload),
            },
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:SubmitFilters": {
      return pipe(
        O.of(s),
        O.filter(State.isReady),
        O.map((s) =>
          State.fetching({
            ...s.payload,
            page: "start",
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:ClearFilters": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.map((s) =>
          State.fetching({
            ...s.payload,
            page: "start",
            advancedFiltersState: "closed",
            filters: FiltersMonoid.empty,
          }),
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:OpenAdvancedFilters": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.filter((s) => s.payload.advancedFiltersState === "closed"),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                advancedFiltersState: "open",
              },
            }) as typeof s,
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:CloseAdvancedFilters": {
      return pipe(
        O.of(s),
        O.filter(isOneOf([State.isReady, State.isFetching])),
        O.filter((s) => s.payload.advancedFiltersState === "open"),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                advancedFiltersState: "closed",
              },
            }) as typeof s,
        ),
        O.getOrElseW(() => s),
      );
    }
    case "Ready:DataManager:Suppliers:Listing:GenerateSuccess": {
      return State.init({
        dataTypeId: s.payload.id,
        openAI: s.payload.openAI.payload,
      });
    }

    case "Ready:DataManager:Suppliers:Listing:Create": {
      return Create.init({
        dataTypeId: s.payload.id,
        openAI: s.payload.openAI,
      });
    }
    default:
      silentUnreachableError(a);
      return s;
  }
}

function getNextDirection(
  d: O.Option<"asc" | "desc">,
): O.Option<"asc" | "desc"> {
  return O.isNone(d)
    ? O.some("asc")
    : pipe(
        d,
        O.chain((d) => (d === "asc" ? O.some("desc") : O.none)),
      );
}
