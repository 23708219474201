import * as NoEmptyStr from "types/src/NoEmptyString";
import { silentUnreachableError } from "utils/exceptions";
import { toUndefined } from "fp-ts/Option";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

import {
  isFieldsValid,
  isFieldsValidated,
  validateFields,
  validateNumberField,
  validateTextField,
} from "./validatords";

export function reducer(s: State.State, a: Actions.Actions): State.State {
  switch (a.type) {
    case "SchemaFields:SetTextValue": {
      const field = s.payload.fields[a.payload.id];

      if (!field) return s;
      if (field.type !== "text") return s;

      const values = {
        ...s.payload.values,
        [a.payload.id]: validateTextField(
          field,
          toUndefined(NoEmptyStr.fromString(a.payload.value)),
        ),
      };

      if (isFieldsValid(values)) {
        return State.valid({
          fields: s.payload.fields,
          values,
        });
      }

      return State.edited({
        fields: s.payload.fields,
        values,
      });
    }
    case "SchemaFields:SetNumberValue": {
      const field = s.payload.fields[a.payload.id];

      if (!field) return s;
      if (field.type !== "number") return s;

      const values = {
        ...s.payload.values,
        [a.payload.id]: validateNumberField(field, a.payload.value),
      };

      if (isFieldsValid(values)) {
        return State.valid({
          fields: s.payload.fields,
          values,
        });
      }

      return State.edited({
        fields: s.payload.fields,
        values,
      });
    }
    case "SchemaFields:Validate": {
      if (isFieldsValidated(s.payload.values)) return s;

      const values = validateFields(s.payload.fields, s.payload.values);

      if (isFieldsValid(values)) {
        return State.valid({
          fields: s.payload.fields,
          values,
        });
      }

      return State.edited({
        fields: s.payload.fields,
        values,
      });
    }
    default: {
      silentUnreachableError(a);
      return s;
    }
  }
}
