import { RootState } from "state-manager";
import { Field } from "ui/components/Field";
import { Label } from "ui/components/Label";
import { useTranslation } from "i18n";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { Input } from "@Containers/Form/Input";
import { Textarea } from "@Containers/Form/Textarea";
import { Toggle } from "@Containers/Form/Toggle";
import { Combobox } from "@Containers/Form/Combobox";
import { useMemo } from "react";
import { useDataTypeEntityTitle } from "@Hooks/useDataTypeEntityTitle";
import { dataTypeEntities, DataTypeEntity } from "types/src/DataType/DataType";
import { flow } from "fp-ts/function";
import * as Schema from "../Schema";

interface FormProps {
  name: string;
  description: string;
  default: boolean;
  entity: DataTypeEntity;
  schema: ReturnType<Schema.SchemaProps["selector"]>;
}

type SchemaDispatch = Schema.SchemaProps["dispatch"];

interface Dispatcher extends SchemaDispatch {
  setName: (name: string) => void;
  setDescription: (description: string) => void;
  setDefault: (default$: boolean) => void;
  setEntity: (entity: DataTypeEntity) => void;
}

export interface DataTypeFormProps {
  selector: (s: RootState) => FormProps;
  dispatch: Dispatcher;
}

export function DataTypeForm({ selector, dispatch }: DataTypeFormProps) {
  const { t } = useTranslation();
  const entityTitle = useDataTypeEntityTitle();
  const name$ = flow(selector, nameSelector);
  const desc$ = flow(selector, descSelector);
  const default$ = flow(selector, defaultSelector);
  const entity$ = flow(selector, entitySelector);
  const schema$ = flow(selector, schemaSelector);

  const entities = useMemo(() => {
    return dataTypeEntities.map((entity) => ({
      value: entity,
      label: entityTitle(entity),
    }));
  }, [entityTitle]);

  return (
    <FormWrapper>
      <Field>
        <Label>{t("Name")}</Label>
        <Input value$={name$} onChange={dispatch.setName} />
      </Field>
      <Field>
        <Label>{t("Description")}</Label>
        <Textarea rows={5} value$={desc$} onChange={dispatch.setDescription} />
      </Field>
      <Field>
        <Toggle value$={default$} onChange={dispatch.setDefault}>
          {t("Make default")}
        </Toggle>
      </Field>
      <Field>
        <Combobox<DataTypeEntity>
          value$={entity$}
          onChange={dispatch.setEntity}
          options={entities}
        />
      </Field>
      <Schema.Schema selector={schema$} dispatch={dispatch} />
    </FormWrapper>
  );
}

const nameSelector = (s: FormProps) => s.name;
const descSelector = (s: FormProps) => s.description;
const defaultSelector = (s: FormProps) => s.default;
const entitySelector = (s: FormProps) => s.entity;
const schemaSelector = (s: FormProps) => s.schema;
