import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  forkJoin,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  scan,
  skip,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError } from "ds";
import {
  deleteItemMovements,
  executeItemMovements,
  getItemMovements,
} from "ds/ItemMovements";
import { shallowEqualObjects } from "shallow-equal";
import { ItemMovementId } from "types/src/ItemMovements/ItemMovement";
import * as Arr from "fp-ts/Array";
import * as Str from "fp-ts/string";
import { Eq } from "fp-ts/Eq";
import { getDataTypes } from "ds/DataTypes";
import * as E from "fp-ts/Either";
import { Epic } from "../../../../../../../../types/RootEpic";
import { FiltersEq } from "./types/Filters";
import { getFetchVars } from "./transformers";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

export const epic: Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
> = (state$, { pyckAdminClient$: dep$ }) => {
  const loading$ = state$.pipe(
    filter(State.isLoading),
    map(getFetchVars),
    distinctUntilChanged(shallowEqualObjects),
    withLatestFrom(dep$),
    switchMap(([vars, client]) => {
      return forkJoin({
        items: from(getItemMovements(client, vars)),
        dataTypes: from(
          getDataTypes(client, {
            where: {
              entity: ["item"],
            },
          }),
        ),
      }).pipe(
        map((r) => {
          if (E.isLeft(r.items)) return r.items;
          if (E.isLeft(r.dataTypes)) return r.dataTypes;
          return E.right({
            items: r.items.right,
            dataTypes: r.dataTypes.right,
          });
        }),
        map(
          E.map((r) =>
            Actions.loadSuccess({
              dataTypes: r.dataTypes.items,
              items: r.items.items,
              total: r.items.totalCount,
              pageInfo: r.items.pageInfo,
            }),
          ),
        ),
        map(
          E.getOrElse<DsError, Actions.Actions>(() =>
            Actions.loadFail({
              type: undefined,
            }),
          ),
        ),
      );
    }),
  );

  const fetch$ = state$.pipe(
    filter(State.isFetching),
    map(getFetchVars),
    distinctUntilChanged(shallowEqualObjects),
    withLatestFrom(dep$),
    switchMap(([vars, client]) => {
      return from(getItemMovements(client, vars)).pipe(
        map(
          E.map((r) =>
            Actions.fetchSuccess({
              items: r.items,
              total: r.totalCount,
              pageInfo: r.pageInfo,
            }),
          ),
        ),
        map(
          E.getOrElse<DsError, Actions.Actions>(() =>
            Actions.loadFail({
              type: undefined,
            }),
          ),
        ),
      );
    }),
  );

  const remove$ = state$.pipe(
    filter(State.isReady),
    map((s) =>
      s.payload.items
        .filter((i) => i.actionState === "removing")
        .map((i) => i.id),
    ),
    scan(
      ([pendingItems], removingItems) => {
        return [
          removingItems,
          Arr.difference(Str.Eq as Eq<ItemMovementId>)(pendingItems)(
            removingItems,
          ),
        ] as [ItemMovementId[], ItemMovementId[]];
      },
      [[], []] as [ItemMovementId[], ItemMovementId[]],
    ),
    map(([, toRemove]) => toRemove),
    filter((i) => i.length > 0),
    withLatestFrom(dep$),
    mergeMap(([toRemove, client]) => {
      return from(deleteItemMovements(client, toRemove)).pipe(
        map(() => Actions.removeSuccess(toRemove)),
        catchError(() => of(Actions.removeFail(toRemove))),
      );
    }),
  );

  const execute$ = state$.pipe(
    filter(State.isReady),
    map((s) =>
      s.payload.items
        .filter((i) => i.actionState === "executing")
        .map((i) => i.id),
    ),
    scan(
      ([pendingItems], executingItems) => {
        return [
          executingItems,
          Arr.difference(Str.Eq as Eq<ItemMovementId>)(pendingItems)(
            executingItems,
          ),
        ] as [ItemMovementId[], ItemMovementId[]];
      },
      [[], []] as [ItemMovementId[], ItemMovementId[]],
    ),
    map(([, toRemove]) => toRemove),
    filter((i) => i.length > 0),
    withLatestFrom(dep$),
    mergeMap(([toRemove, client]) => {
      return from(executeItemMovements(client, toRemove)).pipe(
        map(() => Actions.executeSuccess(toRemove)),
        catchError(() => of(Actions.executeFail(toRemove))),
      );
    }),
  );

  const applyFilters$ = state$.pipe(
    filter(State.isReady),
    map((s) => s.payload.filters),
    distinctUntilChanged(FiltersEq.equals),
    skip(1),
    debounceTime(500),
    map(Actions.submitFilters),
  );

  return merge(loading$, fetch$, remove$, execute$, applyFilters$);
};
