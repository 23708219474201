import {
  catchError,
  distinctUntilChanged,
  filter,
  forkJoin,
  from,
  map,
  merge,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError } from "ds";
import { shallowEqualObjects } from "shallow-equal";
import {
  createRepositories,
  deleteRepositories,
  getRepositories,
  updateRepositories,
} from "ds/Repositories";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { getDataTypes } from "ds/DataTypes";
import { Repo } from "types/src/Repositories/Repository";
import { DataType } from "types/src/DataType/DataType";
import { dsErrorNotification } from "../../../Notifications/epic";
import { Epic } from "../../../../types/RootEpic";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

export const epic: Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
> = (state$, { pyckAdminClient$ }) => {
  const loading$ = state$.pipe(
    filter(State.isLoading),
    distinctUntilChanged(shallowEqualObjects),
    withLatestFrom(pyckAdminClient$),
    switchMap(([vars, client]) => {
      return from(
        getDataTypes(client, {
          where: {
            entity: ["repository"],
          },
        }),
      ).pipe(
        switchMap((dt) => {
          let where = {};
          if (E.isRight(dt)) {
            where = {
              dataTypes: [
                dt.right.items.find((v) => v.default)?.id ??
                  (dt.right.items[0]?.id as DataType["id"]),
              ],
            };
          }

          return from(
            getRepositories(client, {
              where,
            }),
          ).pipe(
            map(
              flow(
                (items) => {
                  if (E.isLeft(dt)) return dt;
                  if (E.isLeft(items)) return items;

                  return E.right({
                    dataTypes: dt.right
                      ? E.right(dt.right.items)
                      : E.left({ type: "error" }),
                    items: items.right?.items,
                  });
                },
                E.map((r) => {
                  if (E.isLeft(r.dataTypes))
                    return Actions.loadFail({ type: undefined });

                  return Actions.loadSuccess({
                    items: r.items.map((v) => ({
                      ...v,
                      data: v.fields as Repo["data"],
                    })),
                    dataTypes: r.dataTypes.right,
                  });
                }),
                E.getOrElse<DsError, Actions.Actions>(() =>
                  Actions.loadFail({ type: undefined }),
                ),
              ),
            ),
          );
        }),
      );
    }),
  );

  const changeDataType$ = state$.pipe(
    filter(State.isActiveDataType),
    distinctUntilChanged(shallowEqualObjects),
    withLatestFrom(pyckAdminClient$),
    switchMap(([vars, client]) => {
      return forkJoin({
        items: from(
          getRepositories(client, {
            where: {
              dataTypes: [vars.payload.activeDataType],
            },
          }),
        ),
      }).pipe(
        map(
          flow(
            (vs) => {
              if (E.isLeft(vs.items)) return vs.items;
              return E.right({
                items: vs.items.right,
              });
            },
            E.map((r) => {
              return Actions.changeActiveDataTypeSuccess({
                items: r.items.items.map((v) => ({
                  ...v,
                  data: v.fields as Repo["data"],
                })),
                dataTypes: vars.payload.dataTypes,
              });
            }),
            E.getOrElse<DsError, Actions.Actions>(() =>
              Actions.changeActiveDataTypeFail({ type: undefined }),
            ),
          ),
        ),
      );
    }),
  );

  const create$ = state$.pipe(
    filter(State.isCreateReposProcessing),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) => {
      return from(
        createRepositories(client, {
          items: s.createItems.map((v) => ({
            name: v.name,
            parentID: v.parentId ? s.idsBinding[v.parentId] : undefined,
            type: "static",
            virtualRepo: false,
            data: v.data,
          })),
          dataTypeId: s.activeDataType,
        }),
      ).pipe(
        dsErrorNotification(
          flow(
            E.map((v) =>
              Actions.createdReposSuccess({
                items: v,
                idsBinding: Object.fromEntries(
                  v.map((t, i) => [[s.createItems[i]?.id], t.id]),
                ),
              }),
            ),
            E.getOrElse<DsError, Actions.Actions>(() =>
              Actions.createdReposFail({ type: undefined }),
            ),
          ),
        ),
        catchError(() => of(Actions.createdReposFail({ type: undefined }))),
      );
    }),
  );

  const update$ = state$.pipe(
    filter(State.isUpdateReposProcessing),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) => {
      return from(
        updateRepositories(client, {
          items: s.updateItems.map((v) => ({
            id: s.idsBinding[v.id] as Repo["id"],
            name: v.name,
            data: v.data,
            parentID: v.parentId ? s.idsBinding[v.parentId] : undefined,
          })),
          dataTypeId: s.activeDataType,
        }),
      ).pipe(
        dsErrorNotification(
          flow(
            E.map((v) => Actions.updatedReposSuccess({ items: v })),
            E.getOrElse<DsError, Actions.Actions>(() =>
              Actions.updatedReposFail({ type: undefined }),
            ),
          ),
        ),
        catchError(() => of(Actions.updatedReposFail({ type: undefined }))),
      );
    }),
  );

  const remove$ = state$.pipe(
    filter(State.isDeleteReposProcessing),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) => {
      return from(
        deleteRepositories(
          client,
          s.itemsIds
            .map((id) => s.idsBinding[id])
            .filter((v): v is Repo["id"] => v !== undefined),
        ),
      ).pipe(
        dsErrorNotification(
          flow(
            E.map((v) => Actions.deleteReposSuccess(undefined)),
            E.getOrElse<DsError, Actions.Actions>(() =>
              Actions.deleteReposFail(undefined),
            ),
          ),
        ),
        catchError(() => of(Actions.deleteReposFail(undefined))),
      );
    }),
  );

  return merge(loading$, create$, remove$, update$, changeDataType$);
};
