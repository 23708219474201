import { silentUnreachableError } from "utils/exceptions";
import { FieldId } from "types/src/DataType/FieldType";
import { strictGuard } from "utils/strictGuard";

// region Actions
export type Actions = SetTextValue | SetNumberValue | Validate;

export const isActions = strictGuard((a: Actions): a is Actions => {
  switch (a.type) {
    case "SchemaFields:SetNumberValue":
    case "SchemaFields:SetTextValue":
    case "SchemaFields:Validate":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});
// endregion

// region SetTextValue
export interface SetTextValue {
  type: "SchemaFields:SetTextValue";
  payload: {
    id: FieldId;
    value: string;
  };
}

export const setTextValue = (
  payload: SetTextValue["payload"],
): SetTextValue => ({
  type: "SchemaFields:SetTextValue",
  payload,
});
// endregion

// region SetNumberValue
export interface SetNumberValue {
  type: "SchemaFields:SetNumberValue";
  payload: {
    id: FieldId;
    value: number;
  };
}

export const setNumberValue = (
  payload: SetNumberValue["payload"],
): SetNumberValue => ({
  type: "SchemaFields:SetNumberValue",
  payload,
});
// endregion

// region Validate
export interface Validate {
  type: "SchemaFields:Validate";
}

export const validate = (): Validate => ({
  type: "SchemaFields:Validate",
});
// endregion
