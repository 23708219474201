import { useTranslation } from "i18n";
import { useCallback } from "react";
import { TranslatedStr } from "types/src/TranslatedStr";
import { DataTypeEntity } from "types/src/DataType/DataType";

export function useDataTypeEntityTitle(): (t: DataTypeEntity) => TranslatedStr {
  const { t } = useTranslation();

  return useCallback(
    (v: DataTypeEntity) => {
      switch (v) {
        case "customer":
          return t("Customer");
        case "delivery":
          return t("Delivery");
        case "item":
          return t("Item");
        case "order":
          return t("Order");
        case "repository":
          return t("Repository");
        case "supplier":
          return t("Supplier");
        case "movement":
          return t("Movement");
      }
    },
    [t],
  );
}
