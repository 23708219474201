import { FieldType, getUniqueId } from "types/src/DataType/FieldType";
import { Field } from "types/src/DataType/Field";

export function getFieldInitialValue<T extends FieldType>(type: T): Field<T> {
  switch (type as FieldType) {
    case "text":
      return {
        type: "text",
        config: {
          minLength: undefined,
          maxLength: undefined,
          placeholder: undefined,
        },
        id: getUniqueId(),
        required: false,
        label: "Text Field",
        description: "",
        order: 0,
      } satisfies Field<"text"> as Field<T>;
    case "number":
      return {
        type: "number",
        config: {
          step: undefined,
          min: 0,
          max: 100,
          placeholder: undefined,
        },
        id: getUniqueId(),
        required: false,
        label: "Number Field",
        description: "",
        order: 0,
      } satisfies Field<"number"> as Field<T>;
  }
}
