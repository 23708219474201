import { Option } from "fp-ts/Option";
import { TranslatedStr } from "../TranslatedStr";
import { UniqId } from "../UniqId";
import { DataTypeSchema } from "./DataTypeSchema";

// region DataTypeId
declare const _dataTypeId: unique symbol;

export type DataTypeId = UniqId & { [_dataTypeId]: "DataTypeId" };
// endregion

export type DataTypeEntity =
  | "item"
  | "repository"
  | "customer"
  | "supplier"
  | "order"
  | "delivery"
  | "movement";

export const dataTypeEntities: DataTypeEntity[] = Object.values({
  item: "item",
  repository: "repository",
  customer: "customer",
  delivery: "delivery",
  order: "order",
  supplier: "supplier",
  movement: "movement",
} satisfies { [k in DataTypeEntity]: k });

export interface DataType {
  id: DataTypeId;
  name: TranslatedStr;
  description: TranslatedStr;
  entity: DataTypeEntity;
  schema: DataTypeSchema;
  jsonSchema: string;
  default: boolean;
  createdAt: Date;
  updatedAt: Option<Date>;
  deletedAt: Option<Date>;
}
