import { Field } from "types/src/DataType/Field";
import { FieldId, FieldType } from "types/src/DataType/FieldType";

export type State = Draft;

// region Draft
export interface DraftPayload {
  fields: {
    [id in FieldId]: Field<FieldType>;
  };
}

export interface Draft {
  type: "Schema:Draft";
  payload: DraftPayload;
}

export const draft = (payload: Draft["payload"]): Draft => ({
  type: "Schema:Draft",
  payload,
});
// endregion

export const init = (): Draft => draft({ fields: {} });
