import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  from,
  map,
  merge,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError } from "ds";
import { getSupplier, updateSupplier } from "ds/Suppliers";
import { getDataType } from "ds/DataTypes";
import { flow } from "fp-ts/function";
import * as E from "fp-ts/Either";
import * as SchemaFields from "../../../../../../../../generic-states/SchemaFields";
import { extractFieldsFromSchema } from "../../../../../../../../generic-states/SchemaFields/utils";
import { Epic } from "../../../../../../../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

export const epic: Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
> = (state$, { pyckAdminClient$ }) => {
  const fieldsSchema$ = SchemaFields.epic(
    state$.pipe(
      filter(State.isLoaded),
      map((s) => s.payload.schema),
    ),
    pyckAdminClient$,
  );

  const loading$ = state$.pipe(
    filter(State.isLoading),
    map((s) => s.payload.id),
    withLatestFrom(pyckAdminClient$),
    switchMap(([id, client]) =>
      from(getSupplier(client, id)).pipe(
        switchMap(
          flow(
            E.mapLeft(Actions.loadFail),
            E.chain(E.fromNullable(Actions.loadFail())),
            E.map((item) => {
              return from(getDataType(client, item.dataTypeId)).pipe(
                map(
                  flow(
                    E.map((dt) =>
                      Actions.loadSuccess({
                        item: item,
                        fields: dt.schema.fields,
                      }),
                    ),
                    E.getOrElse<DsError, Actions.Actions>(() =>
                      Actions.loadFail(),
                    ),
                  ),
                ),
              );
            }),
            E.getOrElse<Actions.Actions, Observable<Actions.Actions>>(of),
          ),
        ),
      ),
    ),
  );

  const update$ = state$.pipe(
    distinctUntilKeyChanged("type"),
    filter(State.isSaving),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) =>
      from(
        updateSupplier(client, {
          id: s.id,
          dataTypeId: s.dataTypeId,
          fields: extractFieldsFromSchema(s.schema.payload.values),
        }),
      ).pipe(
        map(
          flow(
            E.map(Actions.saveSuccess),
            E.getOrElse<DsError, Actions.Actions>(Actions.saveError),
          ),
        ),
        catchError(() => of(Actions.saveError())),
      ),
    ),
  );

  return merge(loading$, update$, fieldsSchema$);
};
