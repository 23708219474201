import { Stock, StockId } from "types/src/Stocks/Stock";
import {
  InventoryItem,
  InventoryItemId,
} from "types/src/InventoryItems/InventoryItem";
import { Repository, RepositoryId } from "types/src/Repositories/Repository";
import { ItemMovement } from "types/src/ItemMovements/ItemMovement";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import { PageInfo } from "types";
import * as ItemsSearch from "../../../../../../../../../generic-states/ItemSearch";

export type Actions =
  | LoadSuccessAction
  | LoadFailAction
  | FetchPrevAction
  | FetchNextAction
  | FetchFirstAction
  | FetchLastAction
  | FetchSuccessAction
  | FetchFailAction
  | SubmitFiltersAction
  | ResetFiltersAction
  | SetWithItemsAction
  | SetQuantityAction
  | SetCreatedAtAction
  | SetLatestAtAction
  | SetSearchAction
  | ItemsSearch.Actions<"Stocks:Stock", unknown, { id: StockId }>
  | ItemsSearch.Actions<
      "Stocks:InventoryItem",
      unknown,
      { id: InventoryItemId }
    >
  | ItemsSearch.Actions<
      "Stocks:Repository",
      unknown,
      { id: RepositoryId; name: string }
    >;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (ItemsSearch.isActions("Stocks:Stock")(a)) return true;
  if (ItemsSearch.isActions("Stocks:InventoryItem")(a)) return true;
  if (ItemsSearch.isActions("Stocks:Repository")(a)) return true;

  if (isLoadFailAction(a)) return true;
  if (isLoadSuccessAction(a)) return true;
  if (isFetchPrevAction(a)) return true;
  if (isFetchNextAction(a)) return true;
  if (isFetchFirstAction(a)) return true;
  if (isFetchLastAction(a)) return true;
  if (isFetchFailAction(a)) return true;
  if (isSubmitFiltersAction(a)) return true;
  if (isFetchSuccessAction(a)) return true;
  if (isResetFiltersAction(a)) return true;
  if (isSetWithItemsAction(a)) return true;
  if (isSetQuantityAction(a)) return true;
  if (isSetCreatedAtAction(a)) return true;
  if (isSetSearchAction(a)) return true;
  if (isSetLatestAtAction(a)) return true;

  silentUnreachableError(a);
  return false;
});

// region LoadFailAction
export interface LoadFailAction {
  type: "Ready:DataManager:Stocks:Listing:LoadFailAction";
}

export const loadFailAction = (): LoadFailAction => ({
  type: "Ready:DataManager:Stocks:Listing:LoadFailAction",
});

export const isLoadFailAction = (s: Actions): s is LoadFailAction =>
  s.type === "Ready:DataManager:Stocks:Listing:LoadFailAction";
// endregion

// region LoadSuccessAction
export interface LoadSuccessAction {
  type: "Ready:DataManager:Stocks:Listing:LoadSuccessAction";
  payload: {
    pageInfo: PageInfo;
    items: Stock[];
    totalCount: number;
    repositories: Repository[];
    inventoryItems: InventoryItem[];
    movements: ItemMovement[];
  };
}

export const loadSuccessAction = (
  payload: LoadSuccessAction["payload"],
): LoadSuccessAction => ({
  type: "Ready:DataManager:Stocks:Listing:LoadSuccessAction",
  payload,
});

export const isLoadSuccessAction = (s: Actions): s is LoadSuccessAction =>
  s.type === "Ready:DataManager:Stocks:Listing:LoadSuccessAction";
// endregion

// region FetchPrevAction
export interface FetchPrevAction {
  type: "Ready:DataManager:Stocks:Listing:FetchPrevAction";
}

export const fetchPrevAction = (): FetchPrevAction => ({
  type: "Ready:DataManager:Stocks:Listing:FetchPrevAction",
});

export const isFetchPrevAction = (s: Actions): s is FetchPrevAction =>
  s.type === "Ready:DataManager:Stocks:Listing:FetchPrevAction";
// endregion

// region FetchNextAction
export interface FetchNextAction {
  type: "Ready:DataManager:Stocks:Listing:FetchNextAction";
}

export const fetchNextAction = (): FetchNextAction => ({
  type: "Ready:DataManager:Stocks:Listing:FetchNextAction",
});

export const isFetchNextAction = (s: Actions): s is FetchNextAction =>
  s.type === "Ready:DataManager:Stocks:Listing:FetchNextAction";
// endregion

// region FetchFirstAction
export interface FetchFirstAction {
  type: "Ready:DataManager:Stocks:Listing:FetchFirstAction";
}

export const fetchFirstAction = (): FetchFirstAction => ({
  type: "Ready:DataManager:Stocks:Listing:FetchFirstAction",
});

export const isFetchFirstAction = (s: Actions): s is FetchFirstAction =>
  s.type === "Ready:DataManager:Stocks:Listing:FetchFirstAction";
// endregion

// region FetchLastAction
export interface FetchLastAction {
  type: "Ready:DataManager:Stocks:Listing:FetchLastAction";
}

export const fetchLastAction = (): FetchLastAction => ({
  type: "Ready:DataManager:Stocks:Listing:FetchLastAction",
});

export const isFetchLastAction = (s: Actions): s is FetchLastAction =>
  s.type === "Ready:DataManager:Stocks:Listing:FetchLastAction";
// endregion

// region FetchFailAction
export interface FetchFailAction {
  type: "Ready:DataManager:Stocks:Listing:FetchFailAction";
}

export const fetchFailAction = (): FetchFailAction => ({
  type: "Ready:DataManager:Stocks:Listing:FetchFailAction",
});

export const isFetchFailAction = (s: Actions): s is FetchFailAction =>
  s.type === "Ready:DataManager:Stocks:Listing:FetchFailAction";
// endregion

// region FetchSuccessAction
export interface FetchSuccessAction {
  type: "Ready:DataManager:Stocks:Listing:FetchSuccessAction";
  payload: {
    items: Stock[];
    pageInfo: PageInfo;
    totalCount: number;
  };
}

export const fetchSuccessAction = (
  payload: FetchSuccessAction["payload"],
): FetchSuccessAction => ({
  type: "Ready:DataManager:Stocks:Listing:FetchSuccessAction",
  payload,
});

export const isFetchSuccessAction = (s: Actions): s is FetchSuccessAction =>
  s.type === "Ready:DataManager:Stocks:Listing:FetchSuccessAction";
// endregion

// region SubmitFiltersAction
export interface SubmitFiltersAction {
  type: "Ready:DataManager:Stocks:Listing:SubmitFiltersAction";
}

export const submitFiltersAction = (): SubmitFiltersAction => ({
  type: "Ready:DataManager:Stocks:Listing:SubmitFiltersAction",
});

export const isSubmitFiltersAction = (a: Actions): a is SubmitFiltersAction =>
  a.type === "Ready:DataManager:Stocks:Listing:SubmitFiltersAction";
// endregion

// region ResetFiltersAction
export interface ResetFiltersAction {
  type: "Ready:DataManager:Stocks:Listing:ResetFiltersAction";
}

export const resetFiltersAction = (): ResetFiltersAction => ({
  type: "Ready:DataManager:Stocks:Listing:ResetFiltersAction",
});

export const isResetFiltersAction = (s: Actions): s is ResetFiltersAction =>
  s.type === "Ready:DataManager:Stocks:Listing:ResetFiltersAction";
// endregion

// region SetCreatedAtAction
export interface SetCreatedAtAction {
  type: "Ready:DataManager:Stocks:Listing:SetCreatedAtAction";
  payload: [Date | undefined, Date | undefined];
}

export const setCreatedAtAction = (
  payload: SetCreatedAtAction["payload"],
): SetCreatedAtAction => ({
  type: "Ready:DataManager:Stocks:Listing:SetCreatedAtAction",
  payload,
});

export const isSetCreatedAtAction = (s: Actions): s is SetCreatedAtAction =>
  s.type === "Ready:DataManager:Stocks:Listing:SetCreatedAtAction";
// endregion

// region SetWithItemsAction
export interface SetWithItemsAction {
  type: "Ready:DataManager:Stocks:Listing:SetWithItemsAction";
  payload: boolean | undefined;
}

export const setWithItemsAction = (
  payload: SetWithItemsAction["payload"],
): SetWithItemsAction => ({
  type: "Ready:DataManager:Stocks:Listing:SetWithItemsAction",
  payload,
});

export const isSetWithItemsAction = (s: Actions): s is SetWithItemsAction =>
  s.type === "Ready:DataManager:Stocks:Listing:SetWithItemsAction";
// endregion

// region SetQuantityAction
export interface SetQuantityAction {
  type: "Ready:DataManager:Stocks:Listing:SetQuantityAction";
  payload: [number | undefined, number | undefined];
}

export const setQuantityAction = (
  payload: SetQuantityAction["payload"],
): SetQuantityAction => ({
  type: "Ready:DataManager:Stocks:Listing:SetQuantityAction",
  payload,
});

export const isSetQuantityAction = (s: Actions): s is SetQuantityAction =>
  s.type === "Ready:DataManager:Stocks:Listing:SetQuantityAction";
// endregion

// region SetSearchAction
export interface SetSearchAction {
  type: "Ready:DataManager:Stocks:Listing:SetSearchAction";
  payload: string;
}

export const setSearchAction = (
  payload: SetSearchAction["payload"],
): SetSearchAction => ({
  type: "Ready:DataManager:Stocks:Listing:SetSearchAction",
  payload,
});

export const isSetSearchAction = (s: Actions): s is SetSearchAction =>
  s.type === "Ready:DataManager:Stocks:Listing:SetSearchAction";
// endregion

// region SetLatestAtAction
export interface SetLatestAtAction {
  type: "Ready:DataManager:Stocks:Listing:SetLatestAtAction";
  payload: boolean;
}

export const setLatestAtAction = (
  payload: SetLatestAtAction["payload"],
): SetLatestAtAction => ({
  type: "Ready:DataManager:Stocks:Listing:SetLatestAtAction",
  payload,
});

export const isSetLatestAtAction = (s: Actions): s is SetLatestAtAction =>
  s.type === "Ready:DataManager:Stocks:Listing:SetLatestAtAction";
// endregion
