import { silentUnreachableError } from "utils/exceptions";
import { keys } from "utils/object";
import { FieldId } from "types/src/DataType/FieldType";
import * as FormValue from "types/src/FormValue";
import * as SchemaFields from "../../../../../../../../generic-states/SchemaFields";
import * as DataGenerator from "../../../../../../../../generic-states/data-genetator";
import * as Listing from "../Listing/types/State";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): State.State | Listing.Loading {
  if (DataGenerator.isActions(a)) {
    if (State.isReady(s) && s.payload.openAI) {
      return State.ready({
        ...s.payload,
        openAI: DataGenerator.reducer(s.payload.openAI, a),
      });
    }

    return s;
  }

  if (SchemaFields.isActions(a)) {
    if (State.isReady(s)) {
      return State.ready({
        dataTypeId: s.payload.dataTypeId,
        schema: SchemaFields.reducer(s.payload.schema, a),
        openAI: s.payload.openAI,
      });
    }

    return s;
  }

  switch (a.type) {
    case "Ready:DataManager:Customers:Create:LoadFail": {
      if (State.isLoading(s)) {
        return State.loadError({
          dataTypeId: s.payload.dataTypeId,
          openAI: s.payload.openAI,
        });
      }

      return s;
    }
    case "Ready:DataManager:Customers:Create:LoadSuccess": {
      if (State.isLoading(s)) {
        return State.ready({
          dataTypeId: s.payload.dataTypeId,
          openAI: s.payload.openAI,
          schema: SchemaFields.init({
            fields: a.payload.fields,
            values: keys(a.payload.fields).reduce(
              (acc, key) => {
                acc[key] = FormValue.initial(undefined);
                return acc;
              },
              {} as Record<FieldId, FormValue.Initial<undefined>>,
            ),
          }),
        });
      }

      return s;
    }
    case "Ready:DataManager:Customers:Create:Submit": {
      if (State.isReady(s)) {
        const validated = SchemaFields.reducer(
          s.payload.schema,
          SchemaFields.validate(),
        );
        if (SchemaFields.isValid(validated)) {
          return State.saving({
            dataTypeId: s.payload.dataTypeId,
            openAI: s.payload.openAI,
            schema: validated,
          });
        } else {
          return State.ready({
            dataTypeId: s.payload.dataTypeId,
            openAI: s.payload.openAI,
            schema: validated,
          });
        }
      }

      return s;
    }
    case "Ready:DataManager:Customers:Create:SaveError": {
      if (State.isSaving(s)) {
        return State.ready({
          dataTypeId: s.payload.dataTypeId,
          openAI: s.payload.openAI,
          schema: s.payload.schema,
        });
      }

      return s;
    }
    case "Ready:DataManager:Customers:Create:SaveSuccess": {
      if (State.isSaving(s)) {
        return Listing.init({
          dataTypeId: s.payload.dataTypeId,
          openAI: s.payload.openAI?.payload,
        });
      }

      return s;
    }
    default: {
      silentUnreachableError(a);
      return s;
    }
  }
}
