import { GetStocksVars } from "ds/Stocks";
import * as O from "fp-ts/Option";
import { fromDate } from "types/src/date/ISODate";
import { pipe } from "fp-ts/function";
import { PageInfo } from "types";
import { Stock } from "types/src/Stocks/Stock";
import { getValue } from "../../../../../../../../generic-states/ItemSearch";
import { Loading, Fetching } from "./types/State";
import { Filters } from "./types/Filters";
import { Item } from "./types/Item";

function stateToStockQuery({
  filters,
  perPage,
  page,
  pageInfo,
}: {
  filters: Filters;
  perPage: number;
  page: "current" | "next" | "prev" | "start" | "end";
  pageInfo: PageInfo | undefined;
}): GetStocksVars {
  const where: GetStocksVars["where"] = {
    or: [
      { hasItemWith: [{ search: filters.search }] },
      { hasRepositoryWith: [{ name: filters.search }] },
    ],
    id: { eq: O.toUndefined(getValue("Stocks:Stock", filters.id))?.id },
    hasItem: filters.withItems,
    quantity: {
      gte: filters.quantity?.[0],
      lte: filters.quantity?.[1],
    },
    hasItemWith: [
      {
        id: O.toUndefined(getValue("Stocks:InventoryItem", filters.item))?.id,
      },
    ],
    hasRepositoryWith: [
      {
        id: O.toUndefined(getValue("Stocks:Repository", filters.repository))
          ?.id,
      },
    ],
    createdAt: {
      gte: pipe(
        filters.createdAt?.[0],
        O.fromNullable,
        O.map(fromDate),
        O.toUndefined,
      ),
      lte: pipe(
        filters.createdAt?.[1],
        O.fromNullable,
        O.map(fromDate),
        O.toUndefined,
      ),
    },
    time: filters.latest
      ? pipe(
          O.fromNullable(filters.createdAt),
          O.chain((v) => O.fromNullable(v[1])),
          O.map(fromDate),
          O.getOrElse(() => fromDate(new Date())),
        )
      : undefined,
  };

  switch (page) {
    case "start":
      return {
        first: perPage,
        where,
      };
    case "prev":
      return {
        last: perPage,
        before: pageInfo?.prevCursor,
        where,
      };
    case "next":
      return {
        first: perPage,
        after: pageInfo?.nextCursor,
        where,
      };
    case "end":
      return {
        last: perPage,
        where,
      };
    case "current":
      return {
        first: perPage,
        where,
      };
  }
}

export function loadingToStocksQuery(s: Loading): GetStocksVars {
  return stateToStockQuery({
    filters: s.payload.filters,
    perPage: s.payload.perPage,
    page: "start",
    pageInfo: undefined,
  });
}
export function fetchToStocksQuery(s: Fetching): GetStocksVars {
  return stateToStockQuery({
    filters: s.payload.filters,
    perPage: s.payload.perPage,
    page: s.payload.page,
    pageInfo: s.payload.pageInfo,
  });
}

export function stockToItem(stock: Stock): Item {
  return {
    id: stock.id,
    repository: {
      id: stock.repository.id,
      name: stock.repository.name,
    },
    item: {
      id: stock.item.id,
    },
    createdAt: stock.createdAt,
    movementId: stock.movementID,
    quantity: stock.quantity,
    incomingStock: stock.incomingStock,
    outgoingStock: stock.outgoingStock,
  };
}
