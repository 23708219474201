import { DataTypeEntity } from "types/src/DataType/DataType";
import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import * as Schema from "../../../../../../../../../generic-states/Schema";

export type State = Ready | Saving;

export const isState = strictGuard((s: State): s is State => {
  const type = s.type as State["type"];

  switch (type) {
    case "Ready:DataManager:DataTypes:Create:Ready":
    case "Ready:DataManager:DataTypes:Create:Saving":
      return true;
    default:
      silentUnreachableError(type);
      return false;
  }
});

// region Ready
export interface ReadyPayload {
  name: string;
  description: string | undefined;
  default: boolean;
  entity: DataTypeEntity;
  schema: Schema.State;
}

export interface Ready {
  type: "Ready:DataManager:DataTypes:Create:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Ready:DataManager:DataTypes:Create:Ready",
  payload,
});

export const isReady = (s: State): s is Ready =>
  s.type === "Ready:DataManager:DataTypes:Create:Ready";
// endregion

// region Saving
export interface SavingPayload extends ReadyPayload {}

export interface Saving {
  type: "Ready:DataManager:DataTypes:Create:Saving";
  payload: SavingPayload;
}

export const saving = (payload: Saving["payload"]): Saving => ({
  type: "Ready:DataManager:DataTypes:Create:Saving",
  payload,
});

export const isSaving = (s: State): s is Saving =>
  s.type === "Ready:DataManager:DataTypes:Create:Saving";
// endregion

export const init = () =>
  ready({
    entity: "item",
    default: false,
    name: "",
    description: undefined,
    schema: Schema.init(),
  });
