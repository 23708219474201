import { silentUnreachableError } from "utils/exceptions";
import { keys } from "utils/object";
import { FieldId, FieldType, FieldValue } from "types/src/DataType/FieldType";
import * as FormValue from "types/src/FormValue";
import * as SchemaFields from "../../../../../../../../generic-states/SchemaFields";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

export function reducer(s: State.State, a: Actions.Actions): State.State {
  if (SchemaFields.isActions(a)) {
    if (State.isReady(s)) {
      return State.ready({
        customerId: s.payload.customerId,
        dataTypeId: s.payload.dataTypeId,
        schema: SchemaFields.reducer(s.payload.schema, a),
      });
    }

    return s;
  }

  switch (a.type) {
    case "Ready:DataManager:Customers:Edit:LoadFail": {
      if (State.isLoading(s)) {
        return State.loadError(s.payload);
      }

      return s;
    }
    case "Ready:DataManager:Customers:Edit:LoadSuccess": {
      if (State.isLoading(s)) {
        return State.ready({
          customerId: a.payload.customer.id,
          dataTypeId: a.payload.customer.dataTypeId,
          schema: SchemaFields.init({
            fields: a.payload.fields,
            values: keys(a.payload.fields).reduce(
              (acc, key) => {
                acc[key] = FormValue.initial(a.payload.customer.fields[key]);
                return acc;
              },
              {} as Record<
                FieldId,
                FormValue.Initial<FieldValue<FieldType> | undefined>
              >,
            ),
          }),
        });
      }

      return s;
    }
    case "Ready:DataManager:Customers:Edit:Submit": {
      if (State.isReady(s)) {
        const validated = SchemaFields.reducer(
          s.payload.schema,
          SchemaFields.validate(),
        );

        if (SchemaFields.isValid(validated)) {
          return State.saving({
            ...s.payload,
            schema: validated,
          });
        } else {
          return State.ready({
            ...s.payload,
            schema: validated,
          });
        }
      }

      return s;
    }
    case "Ready:DataManager:Customers:Edit:SaveError": {
      if (State.isSaving(s)) {
        return State.ready(s.payload);
      }

      return s;
    }
    case "Ready:DataManager:Customers:Edit:SaveSuccess": {
      if (State.isSaving(s)) {
        return State.ready(s.payload);
      }

      return s;
    }
    default: {
      silentUnreachableError(a);
      return s;
    }
  }
}
