import { StockId } from "types/src/Stocks/Stock";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { RepositoryId } from "types/src/Repositories/Repository";
import { unreachableError } from "utils/exceptions";
import * as O from "fp-ts/Option";
import { fromString } from "types/src/NoEmptyString";
import { pipe } from "fp-ts/function";
import * as ItemsSearch from "../../../../../../../../generic-states/ItemSearch";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import { stockToItem } from "./transformers";

const itemsSearchStocks = ItemsSearch.createReducer<
  "Stocks:Stock",
  unknown,
  { id: StockId }
>("Stocks:Stock", (a, b) => a.id === b.id);
const itemsSearchItems = ItemsSearch.createReducer<
  "Stocks:InventoryItem",
  unknown,
  { id: InventoryItemId }
>("Stocks:InventoryItem", (a, b) => a.id === b.id);
const repositorySearchItems = ItemsSearch.createReducer<
  "Stocks:Repository",
  unknown,
  { id: RepositoryId; name: string }
>("Stocks:Repository", (a, b) => a.id === b.id);

export function reducer(s: State.State, a: Actions.Actions): State.State {
  if (ItemsSearch.isActions("Stocks:Stock")(a)) {
    if (State.isLoaded(s))
      return {
        ...s,
        payload: {
          ...s.payload,
          filters: {
            ...s.payload.filters,
            id: itemsSearchStocks(s.payload.filters.id, a),
          },
        },
      } as typeof s;

    return s;
  }
  if (ItemsSearch.isActions("Stocks:InventoryItem")(a)) {
    if (State.isLoaded(s))
      return {
        ...s,
        payload: {
          ...s.payload,
          filters: {
            ...s.payload.filters,
            item: itemsSearchItems(s.payload.filters.item, a),
          },
        },
      } as typeof s;

    return s;
  }
  if (ItemsSearch.isActions("Stocks:Repository")(a)) {
    if (State.isLoaded(s))
      return {
        ...s,
        payload: {
          ...s.payload,
          filters: {
            ...s.payload.filters,
            repository: repositorySearchItems(s.payload.filters.repository, a),
          },
        },
      } as typeof s;

    return s;
  }

  if (Actions.isLoadFailAction(a)) {
    if (State.isLoading(s)) return State.loadError(s.payload);
    return s;
  }
  if (Actions.isLoadSuccessAction(a)) {
    if (State.isLoading(s))
      return State.ready({
        ...s.payload,
        total: a.payload.totalCount,
        items: a.payload.items.map(stockToItem),
        pageInfo: a.payload.pageInfo,
        filters: {
          ...s.payload.filters,
          id: ItemsSearch.isSelected("Stocks:Stock")(s.payload.filters.id)
            ? ItemsSearch.selected("Stocks:Stock")({
                query: s.payload.filters.id.payload.query,
                item: s.payload.filters.id.payload.item,
                items: a.payload.items,
              })
            : ItemsSearch.idle("Stocks:Stock")({
                query: s.payload.filters.id.payload.query,
                items: a.payload.items,
              }),
          item: ItemsSearch.isSelected("Stocks:InventoryItem")(
            s.payload.filters.item,
          )
            ? ItemsSearch.selected("Stocks:InventoryItem")({
                query: s.payload.filters.item.payload.query,
                item: s.payload.filters.item.payload.item,
                items: a.payload.inventoryItems,
              })
            : ItemsSearch.idle("Stocks:InventoryItem")({
                query: s.payload.filters.item.payload.query,
                items: a.payload.inventoryItems,
              }),
          repository: ItemsSearch.isSelected("Stocks:Repository")(
            s.payload.filters.repository,
          )
            ? ItemsSearch.selected("Stocks:Repository")({
                query: s.payload.filters.repository.payload.query,
                item: s.payload.filters.repository.payload.item,
                items: a.payload.repositories,
              })
            : ItemsSearch.idle("Stocks:Repository")({
                query: s.payload.filters.repository.payload.query,
                items: a.payload.repositories,
              }),
        },
      });
    return s;
  }
  if (Actions.isFetchFirstAction(a)) {
    if (State.isReady(s) && s.payload.pageInfo.startCursor)
      return State.fetching({ ...s.payload, page: "start" });
    return s;
  }
  if (Actions.isFetchLastAction(a)) {
    if (State.isReady(s) && s.payload.pageInfo.endCursor)
      return State.fetching({ ...s.payload, page: "end" });
    return s;
  }
  if (Actions.isFetchPrevAction(a)) {
    if (State.isReady(s) && s.payload.pageInfo.prevCursor)
      return State.fetching({ ...s.payload, page: "prev" });
    return s;
  }
  if (Actions.isFetchNextAction(a)) {
    if (State.isReady(s) && s.payload.pageInfo.nextCursor)
      return State.fetching({ ...s.payload, page: "next" });
    return s;
  }
  if (Actions.isFetchFailAction(a)) {
    if (State.isFetching(s)) return State.ready(s.payload);
    return s;
  }
  if (Actions.isFetchSuccessAction(a)) {
    if (State.isFetching(s))
      return State.ready({
        ...s.payload,
        items: a.payload.items.map(stockToItem),
        pageInfo: a.payload.pageInfo,
      });
    return s;
  }
  if (Actions.isResetFiltersAction(a)) {
    if (State.isLoaded(s))
      return State.fetching({
        ...s.payload,
        page: "start",
        filters: {
          search: undefined,
          id: ItemsSearch.idle("Stocks:Stock")({
            query: O.none,
            items: s.payload.filters.id.payload.items,
          }),
          item: ItemsSearch.idle("Stocks:InventoryItem")({
            query: O.none,
            items: s.payload.filters.item.payload.items,
          }),
          repository: ItemsSearch.idle("Stocks:Repository")({
            query: O.none,
            items: s.payload.filters.repository.payload.items,
          }),
          latest: false,
        },
      });
    return s;
  }
  if (Actions.isSubmitFiltersAction(a)) {
    if (State.isReady(s))
      return State.fetching({
        ...s.payload,
        page: "start",
      });
    return s;
  }
  if (Actions.isSetWithItemsAction(a)) {
    if (State.isLoaded(s))
      return State.ready({
        ...s.payload,
        filters: {
          ...s.payload.filters,
          withItems: a.payload,
        },
      });
    return s;
  }
  if (Actions.isSetQuantityAction(a)) {
    if (State.isLoaded(s))
      return State.ready({
        ...s.payload,
        filters: {
          ...s.payload.filters,
          quantity: a.payload,
        },
      });
    return s;
  }
  if (Actions.isSetCreatedAtAction(a)) {
    if (State.isLoaded(s))
      return State.ready({
        ...s.payload,
        filters: {
          ...s.payload.filters,
          createdAt: a.payload,
        },
      });
    return s;
  }
  if (Actions.isSetLatestAtAction(a)) {
    if (State.isLoaded(s))
      return State.ready({
        ...s.payload,
        filters: {
          ...s.payload.filters,
          latest: a.payload,
        },
      });
    return s;
  }
  if (Actions.isSetSearchAction(a)) {
    if (State.isLoaded(s))
      return State.ready({
        ...s.payload,
        filters: {
          ...s.payload.filters,
          search: pipe(a.payload, fromString, O.toUndefined),
        },
      });
    return s;
  }

  unreachableError(a);
  return s;
}
