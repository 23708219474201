import { RootState, useSelector } from "state-manager";
import { FieldValue, FieldValueError } from "types/src/DataType/FieldType";
import { Input } from "@Containers/Form/Input";
import { Field } from "types/src/DataType/Field";
import { flow } from "fp-ts/function";
import * as FormValue from "types/src/FormValue";
import { Field as FormField, Message } from "ui/components/Field";
import { Label } from "ui/components/Label";
import { shallowEqualObjects } from "shallow-equal";
import { useFieldErrorMessage } from "@Hooks/useFieldErrorMessage";

export interface TextFieldProps {
  field$: (s: RootState) => Field<"text">;
  value$: (
    s: RootState,
  ) => FormValue.Value<
    FieldValueError<"text">,
    FieldValue<"text"> | undefined,
    string | undefined
  >;
  onChange: (v: string | undefined) => void;
}

export function TextField({ value$, onChange, field$ }: TextFieldProps) {
  const { label, placeholder } = useSelector(
    flow(field$, (f) => ({
      label: f.label,
      placeholder: f.config.placeholder,
    })),
    shallowEqualObjects,
  );
  const getErrMsg = useFieldErrorMessage("text");
  const error = useSelector(
    flow(value$, FormValue.getError),
    shallowEqualObjects,
  );
  const _v$ = flow(value$, (v) => v.value ?? "");

  return (
    <FormField>
      <Label>{label}</Label>
      <Input
        placeholder={placeholder}
        value$={_v$}
        onChange={onChange}
        validation={error ? "error" : undefined}
      />
      {error && <Message validation={"error"}>{getErrMsg(error)}</Message>}
    </FormField>
  );
}
