import { FieldConfig, FieldId, FieldType } from "types/src/DataType/FieldType";
import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";

export type Actions =
  | Add
  | Remove
  | SetLabel
  | SetDesc
  | SetRequired
  | SetConfig;

export const isActions = strictGuard((a: Actions): a is Actions => {
  switch (a.type) {
    case "Schema:Add":
    case "Schema:Remove":
    case "Schema:SetLabel":
    case "Schema:SetRequired":
    case "Schema:SetConfig":
    case "Schema:SetDesc":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});

// region Add
export interface Add {
  type: "Schema:Add";
  payload: FieldType;
}

export const add = (payload: Add["payload"]): Add => ({
  type: "Schema:Add",
  payload,
});
// endregion

// region Remove
export interface Remove {
  type: "Schema:Remove";
  payload: FieldId;
}

export const remove = (payload: Remove["payload"]): Remove => ({
  type: "Schema:Remove",
  payload,
});
// endregion

// region SetLabel
export interface SetLabel {
  type: "Schema:SetLabel";
  payload: {
    id: FieldId;
    value: string;
  };
}

export const setLabel = (payload: SetLabel["payload"]): SetLabel => ({
  type: "Schema:SetLabel",
  payload,
});
// endregion

// region SetDesc
export interface SetDesc {
  type: "Schema:SetDesc";
  payload: {
    id: FieldId;
    value: string;
  };
}

export const setDesc = (payload: SetDesc["payload"]): SetDesc => ({
  type: "Schema:SetDesc",
  payload,
});
// endregion

// region SetRequired
export interface SetRequired {
  type: "Schema:SetRequired";
  payload: {
    id: FieldId;
    value: boolean;
  };
}

export const setRequired = (payload: SetRequired["payload"]): SetRequired => ({
  type: "Schema:SetRequired",
  payload,
});
// endregion

// region SetConfig
export interface SetConfig {
  type: "Schema:SetConfig";
  payload: {
    id: FieldId;
    value: FieldConfig<FieldType>;
  };
}

export const setConfig = (payload: SetConfig["payload"]): SetConfig => ({
  type: "Schema:SetConfig",
  payload,
});
// endregion
