import {
  FieldId,
  FieldType,
  FieldValue,
  FieldValueError,
} from "types/src/DataType/FieldType";
import { Field } from "types/src/DataType/Field";
import * as FormValue from "types/src/FormValue";
import { EditedValue, InitialValue, ValidValue } from "./types/Value";

type ValidateFieldReturn<T extends FieldType> =
  | FormValue.Valid<FieldValue<T> | undefined>
  | FormValue.Invalid<FieldValueError<T>, FieldValue<T> | undefined>;

export function validateField<T extends FieldType>(
  f: Field<T>,
  v: FieldValue<T> | undefined,
): ValidateFieldReturn<T> {
  switch (f.type) {
    case "number":
      return validateNumberField(
        f,
        v as FieldValue<"number">,
      ) as ValidateFieldReturn<T>;
    case "text":
      return validateTextField(
        f,
        v as FieldValue<"text">,
      ) as ValidateFieldReturn<T>;
  }
}

export function validateTextField(
  f: Field<"text">,
  v: FieldValue<"text"> | undefined,
): ValidateFieldReturn<"text"> {
  if (v === undefined) {
    return f.required
      ? FormValue.invalid({ type: "required" }, undefined)
      : FormValue.valid(undefined);
  }

  const minLen = f.config.minLength ?? 0;
  const maxLen = f.config.maxLength ?? Infinity;

  if (minLen === maxLen && v.length !== minLen)
    return FormValue.invalid({ type: "exactLength", value: minLen }, v);
  if (v.length < minLen)
    return FormValue.invalid({ type: "minLength", value: minLen }, v);
  if (v.length > maxLen)
    return FormValue.invalid({ type: "maxLength", value: maxLen }, v);

  return FormValue.valid(v);
}

export function validateNumberField(
  f: Field<"number">,
  v: FieldValue<"number"> | undefined,
): ValidateFieldReturn<"number"> {
  if (v === undefined) {
    return f.required
      ? FormValue.invalid({ type: "required" }, undefined)
      : FormValue.valid(undefined);
  }

  const min = f.config.min ?? -Infinity;
  const max = f.config.max ?? Infinity;

  if (v < min) return FormValue.invalid({ type: "min", value: min }, v);
  if (v > max) return FormValue.invalid({ type: "max", value: max }, v);

  return FormValue.valid(v);
}

export function isFieldsValid(fields: EditedValue): fields is ValidValue {
  const fs = Object.values(fields);
  return fs.every(FormValue.isValid);
}
export function isFieldsValidated(
  fields: InitialValue | EditedValue | ValidValue,
): fields is EditedValue | ValidValue {
  const values = Object.values(fields);
  return values.length > 0 && values.every((v) => !FormValue.isInitial(v));
}

export function validateFields(
  fields: Record<FieldId, Field<FieldType>>,
  values: InitialValue | EditedValue | ValidValue,
): ValidValue | EditedValue {
  return Object.entries(values).reduce(
    (acc, [id, value]) => {
      const i = id as FieldId;
      acc[i] = validateField(fields[i] as Field<FieldType>, value.value);

      return acc;
    },
    {} as EditedValue | ValidValue,
  );
}
