import { ReactElement } from "react";
import {
  CustomFields,
  CustomFieldsProps,
} from "@Containers/Schema/CustomFields";
import { Add } from "./Add";

export interface SchemaProps {
  selector: CustomFieldsProps["selector"];
  dispatch: CustomFieldsProps["dispatch"];
}

export function Schema({ selector, dispatch }: SchemaProps): ReactElement {
  return (
    <>
      <CustomFields selector={selector} dispatch={dispatch} />
      <Add />
    </>
  );
}
