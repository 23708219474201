import { ItemMovement } from "types/src/ItemMovements/ItemMovement";
import { silentUnreachableError } from "utils/exceptions";
import { FieldId, FieldType } from "types/src/DataType/FieldType";
import { Field } from "types/src/DataType/Field";
import {
  InventoryItem,
  InventoryItemId,
} from "types/src/InventoryItems/InventoryItem";
import { Repository, RepositoryId } from "types/src/Repositories/Repository";
import * as O from "fp-ts/Option";
import { DataTypeId } from "types/src/DataType/DataType";
import { strictGuard } from "utils/strictGuard";
import * as SchemaFields from "../../../../../../../../../generic-states/SchemaFields";

// region Actions
export type Actions =
  | LoadSuccess
  | LoadFail
  | Submit
  | SaveError
  | SaveSuccess
  | InventoryItemsSearchResult
  | RepositoriesFromSearchResult
  | RepositoriesToSearchResult
  | SchemaFields.Actions
  | SetItem
  | SetFrom
  | SetTo
  | SearchItem
  | SearchFrom
  | SearchTo
  | SetQuantity
  | SetHandler;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (SchemaFields.isActions(a)) return true;

  switch (a.type) {
    case "Ready:DataManager:ItemMovements:Edit:LoadFail":
    case "Ready:DataManager:ItemMovements:Edit:LoadSuccess":
    case "Ready:DataManager:ItemMovements:Edit:SaveError":
    case "Ready:DataManager:ItemMovements:Edit:SaveSuccess":
    case "Ready:DataManager:ItemMovements:Edit:Submit":
    case "Ready:DataManager:ItemMovements:Edit:InventoryItemsSearchResult":
    case "Ready:DataManager:ItemMovements:Edit:RepositoriesFromSearchResult":
    case "Ready:DataManager:ItemMovements:Edit:RepositoriesToSearchResult":
    case "Ready:DataManager:ItemMovements:Edit:SetFrom":
    case "Ready:DataManager:ItemMovements:Edit:SetItem":
    case "Ready:DataManager:ItemMovements:Edit:SetTo":
    case "Ready:DataManager:ItemMovements:Edit:SearchFrom":
    case "Ready:DataManager:ItemMovements:Edit:SearchTo":
    case "Ready:DataManager:ItemMovements:Edit:SearchItem":
    case "Ready:DataManager:ItemMovements:Edit:SetHandler":
    case "Ready:DataManager:ItemMovements:Edit:SetQuantity":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:ItemMovements:Edit:LoadFail";
}

export const loadFail = (): LoadFail => ({
  type: "Ready:DataManager:ItemMovements:Edit:LoadFail",
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:ItemMovements:Edit:LoadSuccess";
  payload: {
    fields: Record<FieldId, Field<FieldType>>;
    itemMovement: ItemMovement;
    inventoryItems: InventoryItem[];
    repositories: Repository[];
    dataTypeId: DataTypeId;
  };
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:ItemMovements:Edit:LoadSuccess",
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: "Ready:DataManager:ItemMovements:Edit:Submit";
}

export const submit = (): Submit => ({
  type: "Ready:DataManager:ItemMovements:Edit:Submit",
});
// endregion

// region SaveError
export interface SaveError {
  type: "Ready:DataManager:ItemMovements:Edit:SaveError";
}

export const saveError = (): SaveError => ({
  type: "Ready:DataManager:ItemMovements:Edit:SaveError",
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: "Ready:DataManager:ItemMovements:Edit:SaveSuccess";
  payload: ItemMovement;
}

export const saveSuccess = (payload: SaveSuccess["payload"]): SaveSuccess => ({
  type: "Ready:DataManager:ItemMovements:Edit:SaveSuccess",
  payload,
});
// endregion

// region InventoryItemsSearchResult
export interface InventoryItemsSearchResult {
  type: "Ready:DataManager:ItemMovements:Edit:InventoryItemsSearchResult";
  payload: InventoryItem[];
}

export const inventoryItemsSearchResult = (
  payload: InventoryItemsSearchResult["payload"],
): InventoryItemsSearchResult => ({
  type: "Ready:DataManager:ItemMovements:Edit:InventoryItemsSearchResult",
  payload,
});
// endregion

// region RepositoriesFromSearchResult
export interface RepositoriesFromSearchResult {
  type: "Ready:DataManager:ItemMovements:Edit:RepositoriesFromSearchResult";
  payload: Repository[];
}

export const repositoriesFromSearchResult = (
  payload: RepositoriesFromSearchResult["payload"],
): RepositoriesFromSearchResult => ({
  type: "Ready:DataManager:ItemMovements:Edit:RepositoriesFromSearchResult",
  payload,
});
// endregion

// region RepositoriesToSearchResult
export interface RepositoriesToSearchResult {
  type: "Ready:DataManager:ItemMovements:Edit:RepositoriesToSearchResult";
  payload: Repository[];
}

export const repositoriesToSearchResult = (
  payload: RepositoriesToSearchResult["payload"],
): RepositoriesToSearchResult => ({
  type: "Ready:DataManager:ItemMovements:Edit:RepositoriesToSearchResult",
  payload,
});
// endregion

// region SetItem
export interface SetItem {
  type: "Ready:DataManager:ItemMovements:Edit:SetItem";
  payload: InventoryItemId;
}

export const setItem = (payload: SetItem["payload"]): SetItem => ({
  type: "Ready:DataManager:ItemMovements:Edit:SetItem",
  payload,
});
// endregion

// region SetFrom
export interface SetFrom {
  type: "Ready:DataManager:ItemMovements:Edit:SetFrom";
  payload: RepositoryId;
}

export const setFrom = (payload: SetFrom["payload"]): SetFrom => ({
  type: "Ready:DataManager:ItemMovements:Edit:SetFrom",
  payload,
});
// endregion

// region SetTo
export interface SetTo {
  type: "Ready:DataManager:ItemMovements:Edit:SetTo";
  payload: RepositoryId;
}

export const setTo = (payload: SetTo["payload"]): SetTo => ({
  type: "Ready:DataManager:ItemMovements:Edit:SetTo",
  payload,
});
// endregion

// region SearchItem
export interface SearchItem {
  type: "Ready:DataManager:ItemMovements:Edit:SearchItem";
  payload: string;
}

export const searchItem = (payload: SearchItem["payload"]): SearchItem => ({
  type: "Ready:DataManager:ItemMovements:Edit:SearchItem",
  payload,
});
// endregion

// region SearchFrom
export interface SearchFrom {
  type: "Ready:DataManager:ItemMovements:Edit:SearchFrom";
  payload: string;
}

export const searchFrom = (payload: SearchFrom["payload"]): SearchFrom => ({
  type: "Ready:DataManager:ItemMovements:Edit:SearchFrom",
  payload,
});
// endregion

// region SearchTo
export interface SearchTo {
  type: "Ready:DataManager:ItemMovements:Edit:SearchTo";
  payload: string;
}

export const searchTo = (payload: SearchTo["payload"]): SearchTo => ({
  type: "Ready:DataManager:ItemMovements:Edit:SearchTo",
  payload,
});
// endregion

// region SetQuantity
export interface SetQuantity {
  type: "Ready:DataManager:ItemMovements:Edit:SetQuantity";
  payload: O.Option<number>;
}

export const setQuantity = (payload: SetQuantity["payload"]): SetQuantity => ({
  type: "Ready:DataManager:ItemMovements:Edit:SetQuantity",
  payload,
});
// endregion

// region SetHandler
export interface SetHandler {
  type: "Ready:DataManager:ItemMovements:Edit:SetHandler";
  payload: string;
}

export const setHandler = (payload: SetHandler["payload"]): SetHandler => ({
  type: "Ready:DataManager:ItemMovements:Edit:SetHandler",
  payload,
});
// endregion
