import { RootState, useSelector } from "state-manager";
import { FieldConfig, FieldType } from "types/src/DataType/FieldType";
import { Field as FieldDef } from "types/src/DataType/Field";
import { memo, useMemo, useState } from "react";
import { Field as FormField } from "ui/components/Field";
import { Label } from "ui/components/Label";
import { shallowEqualObjects } from "shallow-equal";
import { Input } from "ui/components/Input";
import { Preview } from "ui/layouts/Schema/Preview";
import { GlobalSheet } from "ui/components/Sheet";
import { TypographyMD } from "ui/components/Typography";
import { Settings } from "@Containers/Schema/Settings";
import { useTranslation } from "i18n";
import { Hint } from "ui/components/Hint";
import { flow } from "fp-ts/function";

interface Dispatch {
  remove: () => void;
  setConfig: (config: FieldConfig<FieldType>) => void;
}

export interface FieldProps {
  selector: (state: RootState) => FieldDef<FieldType>;
  dispatch: Dispatch;
}

export const Field = memo(({ selector, dispatch }: FieldProps) => {
  const [open, setOpen] = useState(false);
  const labelSelector = useMemo(
    () => flow(selector, (v) => ({ label: v.label, required: v.required })),
    [selector],
  );
  const descSelector = useMemo(
    () => flow(selector, (v) => v.description),
    [selector],
  );
  const typeSelector = useMemo(() => flow(selector, (v) => v.type), [selector]);

  return (
    <FormField>
      <FieldLabel selector={labelSelector} />
      <FieldDescription selector={descSelector} />
      <Preview
        openSettings={() => setOpen(true)}
        onRemove={dispatch.remove}
        isActive={open}
      >
        <FieldControl selector={selector} />
      </Preview>
      <GlobalSheet
        isOpen={open}
        onClose={() => setOpen(false)}
        header={<SettingsHeader selector={typeSelector} />}
      >
        <Settings selector={selector} />
      </GlobalSheet>
    </FormField>
  );
});

interface SettingsHeaderProps {
  selector: (state: RootState) => FieldType;
}
function SettingsHeader({ selector }: SettingsHeaderProps) {
  const type = useSelector(selector);
  const { t } = useTranslation();

  const title = useMemo(() => {
    switch (type) {
      case "text":
        return t("Text");
      case "number":
        return t("Number");
    }
  }, [type, t]);

  return (
    <TypographyMD>
      {title} {t("settings")}
    </TypographyMD>
  );
}

interface FieldLabelProps {
  selector: (state: RootState) => {
    label: string;
    required: boolean;
  };
}
function FieldLabel({ selector }: FieldLabelProps) {
  const { label, required } = useSelector(selector, shallowEqualObjects);
  return (
    <Label contentEditable htmlFor={"#"}>
      {label}
      {required ? "*" : ""}
    </Label>
  );
}

interface FieldDescriptionProps {
  selector: (state: RootState) => string;
}
function FieldDescription({ selector }: FieldDescriptionProps) {
  const description = useSelector(selector);
  return <Hint>{description}</Hint>;
}

interface FieldControlProps {
  selector: (state: RootState) => FieldDef<FieldType>;
}
function FieldControl({ selector }: FieldControlProps) {
  const fieldsSelectors = flow(selector, (s) => {
    switch (s.type) {
      case "text":
        return {
          type: "text" as const,
          selector: flow(selector, (s) => s.config as FieldConfig<"text">),
        };
      case "number":
        return {
          type: "number" as const,
          selector: flow(selector, (s) => s.config as FieldConfig<"number">),
        };
    }
  });
  const t = useSelector(fieldsSelectors, (a, b) => a.type === b.type);

  switch (t.type) {
    case "text":
      return <FieldText selector={t.selector} />;
    case "number":
      return <FieldNumber selector={t.selector} />;
  }
}

interface FieldTextProps {
  selector: (state: RootState) => FieldConfig<"text">;
}
function FieldText({ selector }: FieldTextProps) {
  const placeholder = useSelector(flow(selector, (r) => r.placeholder));
  return <Input placeholder={placeholder} />;
}

interface FieldNumberProps {
  selector: (state: RootState) => FieldConfig<"number">;
}
function FieldNumber({ selector }: FieldNumberProps) {
  const placeholder = useSelector(flow(selector, (r) => r.placeholder));
  return <Input placeholder={placeholder} />;
}
