import { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { Well } from "@components/Well";

export interface FieldListProps {
  children?: ReactNode;
}

export function FieldList({ children }: FieldListProps): ReactElement {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled(Well)`
  display: flex;
  gap: ${(p) => p.theme.space.sm};
  align-items: center;
  padding: ${(p) => p.theme.space.sm};
`;
