import { ItemMovement } from "types/src/ItemMovements/ItemMovement";
import { toDate } from "types/src/date/ISODate";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { NoEmptyString } from "types/src/NoEmptyString";
import { ItemMovementFragmentFragment } from "../generated/graphql";
import { inventoryItemFragmentToInventoryItem } from "./InventoryItems";
import { repositoryFragmentToRepository } from "./Repositories";

export function itemMovementFragmentToItemMovement(
  fragment: ItemMovementFragmentFragment,
): ItemMovement {
  return {
    id: fragment.id as ItemMovement["id"],
    fields: (fragment.data ?? { fields: {} }).fields as ItemMovement["fields"],
    createdAt: toDate(fragment.createdAt),
    collectionId: fragment.collectionID as ItemMovement["collectionId"],
    orderId: fragment.orderID as ItemMovement["orderId"],
    updatedAt: pipe(
      fragment.updatedAt,
      O.fromNullable,
      O.map(toDate),
      O.getOrElseW(() => undefined),
    ),
    dataTypeId: fragment.dataTypeID as ItemMovement["dataTypeId"],
    executed: fragment.executed ?? false,
    executedAt: pipe(
      fragment.executedAt,
      O.fromNullable,
      O.map(toDate),
      O.getOrElseW(() => undefined),
    ),
    handler: fragment.handler as NoEmptyString,
    quantity: fragment.quantity,
    item: inventoryItemFragmentToInventoryItem(fragment.item),
    from: repositoryFragmentToRepository(fragment.from),
    to: repositoryFragmentToRepository(fragment.to),
  };
}
