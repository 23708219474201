import { silentUnreachableError } from "utils/exceptions";
import { DataTypeId } from "types/src/DataType/DataType";
import { NoEmptyString } from "types/src/NoEmptyString";
import { RepositoryType } from "types/src/Repositories/RepositoryType";
import * as FormValue from "types/src/FormValue";
import { strictGuard } from "utils/strictGuard";
import { Repository } from "types/src/Repositories/Repository";
import * as SchemaFields from "../../../../../../../../../generic-states/SchemaFields";
import * as ItemSearch from "../../../../../../../../../generic-states/ItemSearch";

// region State
export type State = Loading | LoadError | Ready | Submitted | Saving;

export const isState = strictGuard((s: State): s is State => {
  const type = s.type as State["type"];

  switch (type) {
    case "Ready:DataManager:Repositories:Create:Loading":
    case "Ready:DataManager:Repositories:Create:LoadError":
    case "Ready:DataManager:Repositories:Create:Ready":
    case "Ready:DataManager:Repositories:Submitted":
    case "Ready:DataManager:Repositories:Create:Saving":
      return true;
    default:
      silentUnreachableError(type);
      return false;
  }
});
// endregion

// region Loading
export interface LoadingPayload {
  dataTypeId: DataTypeId;
}

export interface Loading {
  type: "Ready:DataManager:Repositories:Create:Loading";
  payload: LoadingPayload;
}

export const loading = (payload: Loading["payload"]): Loading => ({
  type: "Ready:DataManager:Repositories:Create:Loading",
  payload,
});

export const isLoading = (a: State): a is Loading =>
  a.type === "Ready:DataManager:Repositories:Create:Loading";
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {}

export interface LoadError {
  type: "Ready:DataManager:Repositories:Create:LoadError";
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError["payload"]): LoadError => ({
  type: "Ready:DataManager:Repositories:Create:LoadError",
  payload,
});

export const isLoadError = (a: State): a is LoadError =>
  a.type === "Ready:DataManager:Repositories:Create:LoadError";
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  name: FormValue.Value<"required", NoEmptyString, string>;
  type: FormValue.Value<"required", RepositoryType, RepositoryType | undefined>;
  parent: ItemSearch.State<
    "Ready:DataManager:Repositories:Create:Parent",
    "unknown",
    Repository | undefined
  >;
  schema: SchemaFields.State;
  isVirtual: boolean;
}

export interface Ready {
  type: "Ready:DataManager:Repositories:Create:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Ready:DataManager:Repositories:Create:Ready",
  payload,
});

export const isReady = (a: State): a is Ready =>
  a.type === "Ready:DataManager:Repositories:Create:Ready";
// endregion

// region Submitted
export interface SubmittedPayload extends ReadyPayload {
  name: FormValue.SubmittedValue<ReadyPayload["name"]>;
  type: FormValue.SubmittedValue<ReadyPayload["type"]>;
}

export interface Submitted {
  type: "Ready:DataManager:Repositories:Submitted";
  payload: SubmittedPayload;
}

export const submitted = (payload: Submitted["payload"]): Submitted => ({
  type: "Ready:DataManager:Repositories:Submitted",
  payload,
});

export const isSubmitted = (a: State): a is Submitted =>
  a.type === "Ready:DataManager:Repositories:Submitted";
// endregion

// region Saving
export interface SavingPayload extends ReadyPayload {
  name: FormValue.Valid<NoEmptyString>;
  type: FormValue.Valid<RepositoryType>;
  schema: SchemaFields.Valid;
}

export interface Saving {
  type: "Ready:DataManager:Repositories:Create:Saving";
  payload: SavingPayload;
}

export const saving = (payload: Saving["payload"]): Saving => ({
  type: "Ready:DataManager:Repositories:Create:Saving",
  payload,
});

export const isSaving = (a: State): a is Saving =>
  a.type === "Ready:DataManager:Repositories:Create:Saving";
// endregion

export function isLoaded(a: State): a is Exclude<State, Loading | LoadError> {
  return !isLoading(a) && !isLoadError(a);
}

export const init = loading;
