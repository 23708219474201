import { RootState, useSelector } from "state-manager";
import { FieldValue, FieldValueError } from "types/src/DataType/FieldType";
import { Field } from "types/src/DataType/Field";
import { flow } from "fp-ts/function";
import * as FormValue from "types/src/FormValue";
import { Field as FormField, Message } from "ui/components/Field";
import { Label } from "ui/components/Label";
import { Number } from "@Containers/Form/Number";
import { shallowEqualObjects } from "shallow-equal";
import { useFieldErrorMessage } from "@Hooks/useFieldErrorMessage";

export interface NumberFieldProps {
  field$: (s: RootState) => Field<"number">;
  value$: (
    s: RootState,
  ) => FormValue.Value<
    FieldValueError<"number">,
    FieldValue<"number"> | undefined,
    FieldValue<"number"> | undefined
  >;
  onChange: (v: FieldValue<"number"> | undefined) => void;
}

export function NumberField({ value$, onChange, field$ }: NumberFieldProps) {
  const { min, max, label, placeholder } = useSelector(
    flow(field$, (p) => ({
      min: p.config.min,
      max: p.config.max,
      label: p.label,
      placeholder: p.config.placeholder,
    })),
    shallowEqualObjects,
  );
  const getErrMsg = useFieldErrorMessage("number");
  const error = useSelector(
    flow(value$, FormValue.getError),
    shallowEqualObjects,
  );
  const _v$ = flow(value$, (v) => v.value);

  return (
    <FormField>
      <Label>{label}</Label>
      <Number
        placeholder={placeholder}
        value$={_v$}
        onChange={onChange}
        min={min}
        max={max}
        validation={error ? "error" : undefined}
      />
      {error && <Message validation={"error"}>{getErrMsg(error)}</Message>}
    </FormField>
  );
}
