import deepEqual from "deep-equal";
import { shallowEqual } from "shallow-equal";

export function jsonParse(json: string): unknown {
  try {
    return JSON.parse(json);
  } catch (e) {
    return undefined;
  }
}

export function keys<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}
export function entries<T extends object>(
  obj: T,
): Array<[keyof T, T[keyof T]]> {
  return Object.entries(obj) as Array<[keyof T, T[keyof T]]>;
}

export const isObject = (obj: unknown): obj is object => {
  return obj !== null && typeof obj === "object" && !Array.isArray(obj);
};

export const isDeepEqual = <
  T extends Object | Array<unknown>,
  T2 extends T = T,
>(
  a: T,
  b: T2,
): a is T2 => deepEqual(a, b);

export const isShallowEqual = <
  T extends Object | Array<unknown>,
  T2 extends T = T,
>(
  a: T,
  b: T2,
): a is T2 => shallowEqual(a, b);
