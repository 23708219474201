import { RootState } from "state-manager";
import { useMemo } from "react";
import * as Create from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Create";
import { DataTypeForm, DataTypeFormProps } from "@Containers/Form/DataTypeForm";
import * as DataTypesSchema from "state-manager/generic-states/Schema";
import { flow } from "fp-ts/function";
import { FormColumns } from "ui/layouts/FormColumns";
import { dataTypeToJsonSchema, jsonSchemaToDataTypeSchema } from "ds/DataTypes";
import { DataTypeJsonEditor } from "@Containers/Form/DataTypeJsonEditor";

export interface ContentProps {
  selector: (s: RootState) => Create.State;
  dispatch: (a: Create.Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const selectors = useMemo(() => {
    return {
      form: flow(selector, (s) => ({
        name: s.payload.name || "",
        description: s.payload.description || "",
        default: s.payload.default,
        entity: s.payload.entity,
        schema: s.payload.schema,
      })),
      jsonEditorSelector: flow(
        selector,
        (st) => st as typeof st,
        (s) => s.payload,
        (s) => dataTypeToJsonSchema(s.name, s.description, s.schema.payload),
        (s) => JSON.stringify(s, null, 2),
      ),
    };
  }, [selector]);
  const dispatcher = useMemo<DataTypeFormProps["dispatch"]>(
    () => ({
      setEntity: flow(Create.setEntity, dispatch),
      setName: flow(Create.setName, dispatch),
      setDescription: flow(Create.setDescription, dispatch),
      setDefault: flow(Create.setDefault, dispatch),
      setConfig: flow(DataTypesSchema.setConfig, dispatch),
      remove: flow(DataTypesSchema.remove, dispatch),
    }),
    [],
  );

  return (
    <FormColumns>
      <DataTypeForm selector={selectors.form} dispatch={dispatcher} />
      <DataTypeJsonEditor
        value$={selectors.jsonEditorSelector}
        onChange={flow(
          (v) => JSON.parse(v ?? ""),
          jsonSchemaToDataTypeSchema,
          Create.resetSchema,
          dispatch,
        )}
      />
    </FormColumns>
  );
}
