import { matchPath } from "react-router-dom";
import { silentUnreachableError } from "utils/exceptions";
import { DataTypeId } from "types/src/DataType/DataType";
import { IdlePayload } from "state-manager/states/Auth";
import { CustomerId } from "types/src/Customers/Customer";
import { SupplierId } from "types/src/Supplier/Supplier";
import { RepositoryId } from "types/src/Repositories/Repository";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { ItemMovementId } from "types/src/ItemMovements/ItemMovement";
import { RepositoryMovementId } from "types/src/RepositoryMovements/RepositoryMovement";
import { StockId } from "types/src/Stocks/Stock";
import { TransactionId } from "types/src/Transactions/Transaction";

type RoutePattern =
  | "/callback"
  | "/login"
  | "/data-types"
  | "/data-types/add"
  | "/data-types/edit/:id"
  | "/customers"
  | "/customers/:dataTypeId"
  | "/customers/:dataTypeId/add"
  | "/customers/edit/:id"
  | "/suppliers"
  | "/suppliers/:dataTypeId"
  | "/suppliers/:dataTypeId/add"
  | "/suppliers/edit/:id"
  | "/warehouse-builder"
  | "/bpmn-builder"
  | "/graphql-sandbox"
  | "/zitadel-app"
  | "/repositories"
  | "/repositories/:dataTypeId"
  | "/repositories/:dataTypeId/add"
  | "/repositories/edit/:id"
  | "/inventory-items"
  | "/inventory-items/:dataTypeId"
  | "/inventory-items/:dataTypeId/add"
  | "/inventory-items/edit/:id"
  | "/item-movements"
  | "/item-movements/:dataTypeId"
  | "/item-movements/:dataTypeId/add"
  | "/item-movements/edit/:id"
  | "/repository-movements"
  | "/repository-movements/:dataTypeId"
  | "/repository-movements/:dataTypeId/add"
  | "/repository-movements/edit/:id"
  | "/stocks"
  | "/stocks/:id"
  | "/transactions"
  | "/transactions/:id";

export const routes = {
  "/callback": {
    type: "/callback",
    create: () => "/callback" as const,
  } as const,
  "/login": {
    type: "/login",
    create: () => "/login" as const,
  } as const,
  "/data-types": {
    type: "/data-types",
    create: () => "/data-types" as const,
  } as const,
  "/data-types/add": {
    type: "/data-types/add",
    create: () => "/data-types/add" as const,
  } as const,
  "/data-types/edit/:id": {
    type: "/data-types/edit/:id",
    create: (p) => `/data-types/edit/${p.id}` as const,
  } as const,

  "/customers": {
    type: "/customers",
    create: () => "/customers" as const,
  } as const,
  "/customers/:dataTypeId": {
    type: "/customers/:dataTypeId",
    create: (p) => `/customers/${p.dataTypeId}` as const,
  } as const,
  "/customers/:dataTypeId/add": {
    type: "/customers/:dataTypeId/add",
    create: (p) => `/customers/${p.dataTypeId}/add` as const,
  } as const,
  "/customers/edit/:id": {
    type: "/customers/edit/:id",
    create: (p) => `/customers/edit/${p.id}` as const,
  } as const,

  "/suppliers": {
    type: "/suppliers",
    create: () => "/suppliers" as const,
  } as const,
  "/suppliers/:dataTypeId": {
    type: "/suppliers/:dataTypeId",
    create: (p) => `/suppliers/${p.dataTypeId}` as const,
  } as const,
  "/suppliers/:dataTypeId/add": {
    type: "/suppliers/:dataTypeId/add",
    create: (p) => `/suppliers/${p.dataTypeId}/add` as const,
  } as const,
  "/suppliers/edit/:id": {
    type: "/suppliers/edit/:id",
    create: (p) => `/suppliers/edit/${p.id}` as const,
  } as const,

  "/warehouse-builder": {
    type: "/warehouse-builder",
    create: () => `/warehouse-builder` as const,
  } as const,
  "/bpmn-builder": {
    type: "/bpmn-builder",
    create: () => `/bpmn-builder` as const,
  } as const,
  "/zitadel-app": {
    type: "/zitadel-app",
    create: () => `/zitadel-app` as const,
  } as const,
  "/graphql-sandbox": {
    type: "/graphql-sandbox",
    create: () => `/graphql-sandbox` as const,
  } as const,

  "/repositories": {
    type: "/repositories",
    create: () => "/repositories" as const,
  } as const,
  "/repositories/:dataTypeId": {
    type: "/repositories/:dataTypeId",
    create: (p) => `/repositories/${p.dataTypeId}` as const,
  } as const,
  "/repositories/:dataTypeId/add": {
    type: "/repositories/:dataTypeId/add",
    create: (p) => `/repositories/${p.dataTypeId}/add` as const,
  } as const,
  "/repositories/edit/:id": {
    type: "/repositories/edit/:id",
    create: (p) => `/repositories/edit/${p.id}` as const,
  } as const,

  "/inventory-items": {
    type: "/inventory-items",
    create: () => "/inventory-items" as const,
  } as const,
  "/inventory-items/:dataTypeId": {
    type: "/inventory-items/:dataTypeId",
    create: (p) => `/inventory-items/${p.dataTypeId}` as const,
  } as const,
  "/inventory-items/:dataTypeId/add": {
    type: "/inventory-items/:dataTypeId/add",
    create: (p) => `/inventory-items/${p.dataTypeId}/add` as const,
  } as const,
  "/inventory-items/edit/:id": {
    type: "/inventory-items/edit/:id",
    create: (p) => `/inventory-items/edit/${p.id}` as const,
  } as const,

  "/item-movements": {
    type: "/item-movements",
    create: () => "/item-movements" as const,
  } as const,
  "/item-movements/:dataTypeId": {
    type: "/item-movements/:dataTypeId",
    create: (p) => `/item-movements/${p.dataTypeId}` as const,
  } as const,
  "/item-movements/:dataTypeId/add": {
    type: "/item-movements/:dataTypeId/add",
    create: (p) => `/item-movements/${p.dataTypeId}/add` as const,
  } as const,
  "/item-movements/edit/:id": {
    type: "/item-movements/edit/:id",
    create: (p) => `/item-movements/edit/${p.id}` as const,
  } as const,

  "/repository-movements": {
    type: "/repository-movements",
    create: () => "/repository-movements" as const,
  } as const,
  "/repository-movements/:dataTypeId": {
    type: "/repository-movements/:dataTypeId",
    create: (p) => `/repository-movements/${p.dataTypeId}` as const,
  } as const,
  "/repository-movements/:dataTypeId/add": {
    type: "/repository-movements/:dataTypeId/add",
    create: (p) => `/repository-movements/${p.dataTypeId}/add` as const,
  } as const,
  "/repository-movements/edit/:id": {
    type: "/repository-movements/edit/:id",
    create: (p) => `/repository-movements/edit/${p.id}` as const,
  } as const,

  "/stocks": {
    type: "/stocks",
    create: () => "/stocks" as const,
  } as const,
  "/stocks/:id": {
    type: "/stocks/:id",
    create: (p) => `/stocks/${p.id}` as const,
  } as const,

  "/transactions": {
    type: "/transactions",
    create: () => "/transactions" as const,
  } as const,
  "/transactions/:id": {
    type: "/transactions/:id",
    create: (p) => `/transactions/${p.id}` as const,
  } as const,
} satisfies {
  [K in RoutePattern]: {
    create: (params: ExtractParams<K>) => string;
    type: K;
  };
};

type ExtractParam<Path, NextPart> = Path extends `:${infer Param}`
  ? { [k in Param]: string } & NextPart
  : NextPart;

type ExtractParams<Path> = Path extends `${infer Segment}/${infer Rest}`
  ? ExtractParam<Segment, ExtractParams<Rest>>
  : ExtractParam<Path, {}>;

type R = typeof routes;

export type RouteType = R[keyof R];
export type Route = ReturnType<RouteType["create"]>;

function isExactRoute<P extends RoutePattern>(
  p: P,
  path: string,
): path is ReturnType<R[P]["create"]> {
  return !!matchPath(p, path);
}
export function getRouteParams<T extends RoutePattern>(
  pattern: T,
  path: ReturnType<RouteType["create"]>,
): ExtractParams<T>;
export function getRouteParams<T extends RoutePattern>(
  pattern: T,
  path: string,
): ExtractParams<T> | null;
export function getRouteParams<T extends RoutePattern>(
  pattern: T,
  path: string,
): ExtractParams<T> | null {
  return matchPath(pattern, path)?.params as ExtractParams<T> | null;
}

export function getRedirectAction(
  path: string,
): IdlePayload["goTo"] | undefined {
  const p = path as Route;

  if (isExactRoute("/callback", p)) {
    return undefined;
  }

  if (isExactRoute("/login", p)) {
    return undefined;
  }

  if (isExactRoute("/data-types", p)) {
    return { type: "data-types:listing" };
  }

  if (isExactRoute("/data-types/add", p)) {
    return { type: "data-types:create" };
  }

  if (isExactRoute("/data-types/edit/:id", p)) {
    const { id } = getRouteParams("/data-types/edit/:id", p);

    return { type: "data-types:edit", id: id as DataTypeId };
  }

  if (isExactRoute("/customers", p)) {
    return { type: "customers" };
  }

  if (isExactRoute("/customers/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams("/customers/:dataTypeId", p);

    return { type: "customers:data-type", id: dataTypeId as DataTypeId };
  }

  if (isExactRoute("/customers/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams("/customers/:dataTypeId/add", p);

    return { type: "customers:create", dataTypeId: dataTypeId as DataTypeId };
  }

  if (isExactRoute("/customers/edit/:id", p)) {
    const { id } = getRouteParams("/customers/edit/:id", p);

    return { type: "customers:edit", id: id as CustomerId };
  }

  if (isExactRoute("/suppliers", p)) {
    return { type: "suppliers" };
  }

  if (isExactRoute("/suppliers/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams("/suppliers/:dataTypeId", p);

    return { type: "suppliers:data-type", id: dataTypeId as DataTypeId };
  }

  if (isExactRoute("/suppliers/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams("/suppliers/:dataTypeId/add", p);

    return { type: "suppliers:create", dataTypeId: dataTypeId as DataTypeId };
  }

  if (isExactRoute("/suppliers/edit/:id", p)) {
    const { id } = getRouteParams("/suppliers/edit/:id", p);

    return { type: "suppliers:edit", id: id as SupplierId };
  }

  if (isExactRoute("/warehouse-builder", p)) {
    return { type: "warehouse-builder" };
  }
  if (isExactRoute("/bpmn-builder", p)) {
    return { type: "bpmn-builder" };
  }
  if (isExactRoute("/zitadel-app", p)) {
    return { type: "zitadel-app" };
  }

  if (isExactRoute("/bpmn-builder", p)) {
    return { type: "bpmn-builder" };
  }

  if (isExactRoute("/graphql-sandbox", p)) {
    return { type: "graphql-sandbox" };
  }

  if (isExactRoute("/repositories", p)) {
    return { type: "repositories" };
  }

  if (isExactRoute("/repositories/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams("/repositories/:dataTypeId", p);

    return { type: "repositories:data-type", id: dataTypeId as DataTypeId };
  }

  if (isExactRoute("/repositories/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams("/repositories/:dataTypeId/add", p);

    return {
      type: "repositories:create",
      dataTypeId: dataTypeId as DataTypeId,
    };
  }

  if (isExactRoute("/repositories/edit/:id", p)) {
    const { id } = getRouteParams("/repositories/edit/:id", p);

    return { type: "repositories:edit", id: id as RepositoryId };
  }

  if (isExactRoute("/inventory-items", p)) {
    return { type: "inventoryItems" };
  }

  if (isExactRoute("/inventory-items/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams("/inventory-items/:dataTypeId", p);

    return { type: "inventoryItems:data-type", id: dataTypeId as DataTypeId };
  }

  if (isExactRoute("/inventory-items/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams(
      "/inventory-items/:dataTypeId/add",
      p,
    );

    return {
      type: "inventoryItems:create",
      dataTypeId: dataTypeId as DataTypeId,
    };
  }

  if (isExactRoute("/inventory-items/edit/:id", p)) {
    const { id } = getRouteParams("/inventory-items/edit/:id", p);

    return { type: "inventoryItems:edit", id: id as InventoryItemId };
  }

  if (isExactRoute("/item-movements", p)) {
    return { type: "itemMovements" };
  }
  if (isExactRoute("/item-movements/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams("/item-movements/:dataTypeId/add", p);
    return {
      type: "itemMovements:create",
      id: dataTypeId as DataTypeId,
    };
  }
  if (isExactRoute("/item-movements/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams("/item-movements/:dataTypeId", p);

    return { type: "itemMovements:data-type", id: dataTypeId as DataTypeId };
  }
  if (isExactRoute("/item-movements/edit/:id", p)) {
    const { id } = getRouteParams("/item-movements/edit/:id", p);

    return { type: "itemMovements:edit", id: id as ItemMovementId };
  }

  if (isExactRoute("/repository-movements", p)) {
    return { type: "repositoryMovements" };
  }
  if (isExactRoute("/repository-movements/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams(
      "/repository-movements/:dataTypeId/add",
      p,
    );
    return {
      type: "repositoryMovements:create",
      id: dataTypeId as DataTypeId,
    };
  }
  if (isExactRoute("/repository-movements/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams(
      "/repository-movements/:dataTypeId",
      p,
    );

    return {
      type: "repositoryMovements:data-type",
      id: dataTypeId as DataTypeId,
    };
  }
  if (isExactRoute("/repository-movements/edit/:id", p)) {
    const { id } = getRouteParams("/repository-movements/edit/:id", p);

    return { type: "repositoryMovements:edit", id: id as RepositoryMovementId };
  }

  if (isExactRoute("/stocks", p)) {
    return { type: "stocks" };
  }
  if (isExactRoute("/stocks/:id", p)) {
    const { id } = getRouteParams("/stocks/:id", p);
    return { type: "stocks:single", id: id as StockId };
  }

  if (isExactRoute("/transactions", p)) {
    return { type: "transactions" };
  }
  if (isExactRoute("/transactions/:id", p)) {
    const { id } = getRouteParams("/transactions/:id", p);
    return { type: "transactions:single", id: id as TransactionId };
  }

  silentUnreachableError(p);
  return undefined;
}
