import {
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  forkJoin,
  from,
  map,
  merge,
  Observable,
  share,
  switchMap,
} from "rxjs";
import { Client } from "ds";
import { getTransactions } from "ds/Transactions";
import { getRepositories } from "ds/Repositories";
import { getInventoryItems } from "ds/InventoryItems";
import { getItemMovements } from "ds/ItemMovements";
import { sequenceT } from "fp-ts/Apply";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { TransactionId } from "types/src/Transactions/Transaction";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { RepositoryId } from "types/src/Repositories/Repository";
import { Client as OpenAIClient } from "open-ai-ds";
import * as ItemsSearch from "../../../../../../../../generic-states/ItemSearch";
import { Epic } from "../../../../../../../../types/RootEpic";
import { dsErrorNotification } from "../../../../../../../Notifications/epic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import {
  fetchToTransactionsQuery,
  loadingToTransactionsQuery,
} from "./transformers";

const transactionSearchEpic = ItemsSearch.epicCreator<
  "Transactions:Transaction",
  unknown,
  { id: TransactionId }
>("Transactions:Transaction");
const itemSearchEpic = ItemsSearch.epicCreator<
  "Transactions:InventoryItem",
  unknown,
  { id: InventoryItemId }
>("Transactions:InventoryItem");
const repositorySearchEpic = ItemsSearch.epicCreator<
  "Transactions:Repository",
  unknown,
  { id: RepositoryId; name: string }
>("Transactions:Repository");

export const epic: Epic<
  Actions.Actions,
  State.State,
  {
    pyckAdminClient$: Observable<Client>;
    openAIClient$: Observable<OpenAIClient>;
  }
> = (state$, { pyckAdminClient$ }) => {
  const distinctState$ = state$.pipe(distinctUntilKeyChanged("type"), share());

  const loading$ = distinctState$.pipe(
    filter(State.isLoading),
    switchMap((s) =>
      pyckAdminClient$.pipe(
        switchMap((c) => {
          return forkJoin({
            transactions: from(
              getTransactions(c, loadingToTransactionsQuery(s)),
            ),
            repositories: from(
              getRepositories(c, { where: { virtualRepository: false } }),
            ),
            inventoryItems: from(getInventoryItems(c, {})),
            movements: from(getItemMovements(c, {})),
          }).pipe(
            map((r) =>
              sequenceT(E.Apply)(
                r.transactions,
                r.inventoryItems,
                r.repositories,
                r.movements,
              ),
            ),
          );
        }),
        dsErrorNotification(
          flow(
            E.map((v) =>
              Actions.loadSuccessAction({
                pageInfo: v[0].pageInfo,
                items: v[0].items,
                totalCount: v[0].totalCount,
                inventoryItems: v[1].items,
                repositories: v[2].items,
                movements: v[3].items,
              }),
            ),
            E.getOrElseW(() => Actions.loadFailAction()),
          ),
        ),
      ),
    ),
  );

  const fetch$ = distinctState$.pipe(
    filter(State.isFetching),
    switchMap((s) =>
      pyckAdminClient$.pipe(
        switchMap((c) => from(getTransactions(c, fetchToTransactionsQuery(s)))),
        dsErrorNotification(
          flow(
            E.map(Actions.fetchSuccessAction),
            E.getOrElseW(() => Actions.fetchFailAction()),
          ),
        ),
      ),
    ),
  );

  const transactionsSearch$ = pyckAdminClient$.pipe(
    switchMap((c) =>
      transactionSearchEpic(
        state$.pipe(
          filter(State.isLoaded),
          map((s) => s.payload.filters.id),
        ),
        {
          get: (q) =>
            getTransactions(c, {
              where: { id: { eq: q as TransactionId } },
            }).then(E.map((v) => v.items)),
        },
      ),
    ),
  );

  const itemsSearch$ = pyckAdminClient$.pipe(
    switchMap((c) =>
      itemSearchEpic(
        state$.pipe(
          filter(State.isLoaded),
          map((s) => s.payload.filters.item),
        ),
        {
          get: (q) =>
            getInventoryItems(c, { where: { search: q } }).then(
              E.map((v) => v.items),
            ),
        },
      ),
    ),
  );

  const repositories$ = pyckAdminClient$.pipe(
    switchMap((c) =>
      repositorySearchEpic(
        state$.pipe(
          filter(State.isLoaded),
          map((s) => s.payload.filters.repository),
        ),
        {
          get: (q) =>
            getRepositories(c, { where: { name: q } }).then(
              E.map((v) => v.items.map((i) => ({ id: i.id, name: i.name }))),
            ),
        },
      ),
    ),
  );

  const search$ = state$.pipe(
    filter(State.isLoaded),
    map((s) => s.payload.filters.search),
    distinctUntilChanged(),
    debounceTime(500),
    map(Actions.submitFiltersAction),
  );

  return merge(
    loading$,
    fetch$,
    transactionsSearch$,
    itemsSearch$,
    repositories$,
    search$,
  );
};
