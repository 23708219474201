import { FieldType, FieldValueError } from "types/src/DataType/FieldType";
import { TranslatedStr } from "types/src/TranslatedStr";
import { useMemo } from "react";
import { useTranslation } from "i18n";

export function useFieldErrorMessage<T extends FieldType>(
  type: T,
): (err: FieldValueError<T>) => TranslatedStr {
  const { t } = useTranslation();

  return useMemo(() => {
    const _type = type as FieldType;
    switch (_type) {
      case "number":
        return ((err: FieldValueError<"number">): TranslatedStr => {
          switch (err.type) {
            case "required":
              return t("Required");
            case "max":
              return t("Values should be lower then {{value}}", {
                value: err.value,
              });
            case "min":
              return t("Values should be higher then {{value}}", {
                value: err.value,
              });
          }
        }) as (err: FieldValueError<T>) => TranslatedStr;
      case "text":
        return ((err: FieldValueError<"text">): TranslatedStr => {
          switch (err.type) {
            case "required":
              return t("Required");
            case "exactLength":
              return t("Text length should be of {{value}} characters", {
                value: err.value,
              });
            case "maxLength":
              return t("Text should be shorter then {{value}} characters", {
                value: err.value,
              });
            case "minLength":
              return t("Text should be longer then {{value}} characters", {
                value: err.value,
              });
          }
        }) as (err: FieldValueError<T>) => TranslatedStr;
    }
  }, [type, t]);
}
