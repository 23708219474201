import { ReactElement, useState } from "react";
import { Selector, useSelector, useStore } from "state-manager";
import * as Listing from "state-manager/states/Ready/states/DataManager/states/Stocks/states/Listing";
import { flow, pipe } from "fp-ts/function";
import { eqByKey } from "utils/eq";
import { unreachableError } from "utils/exceptions";
import { Loading } from "ui/layouts/Loading";
import { AdvancedFilters } from "ui/layouts/Filters/AdvancedFilters";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { ItemSearchInput } from "@Containers/Form/ItemSearchInput";
import { useTranslation } from "i18n";
import { StockId } from "types/src/Stocks/Stock";
import { TranslatedStr } from "types/src/TranslatedStr";
import { RepositoryId } from "types/src/Repositories/Repository";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { Picker } from "@Containers/Form/Picker";
import { Field } from "ui/components/Field";
import { Label } from "ui/components/Label";
import { Number } from "@Containers/Form/Number";
import { DatePickerInput } from "@Containers/Form/DatePickerInput";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import { FiltersWrapper } from "ui/layouts/Listing/FiltersWrapper";
import { SearchInput } from "@Containers/Form/SearchInput";
import { FiltersButton } from "ui/layouts/Filters/FiltersButton";
import * as ItemSearch from "state-manager/generic-states/ItemSearch";
import { Toggle } from "@Containers/Form/Toggle";
import { ItemsTable } from "./ItemsTable";

export interface ContentProps {
  selector: Selector<Listing.State>;
  dispatch: (a: Listing.Actions) => void;
}

export function Content(p: ContentProps): ReactElement {
  const [open, setOpen] = useState(false);
  const data = useSelector(
    flow(p.selector, (s) => {
      if (Listing.isLoading(s)) return { type: "loading" } as const;
      if (Listing.isReady(s) || Listing.isFetching(s)) {
        return {
          type: "items",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;
      }
      if (Listing.isLoadError(s)) return { type: "error" } as const;

      unreachableError(s);
      return { type: "loading" } as const;
    }),
    eqByKey("type"),
  );

  switch (data.type) {
    case "loading":
      return <Loading />;
    case "items":
      return (
        <>
          <ListingWrapper
            header={
              <FiltersWrapper>
                <SearchInput
                  value$={flow(
                    p.selector,
                    (v) => v.payload.filters.search ?? "",
                  )}
                  onChange={flow(Listing.setSearchAction, p.dispatch)}
                />
                <FiltersTrigger
                  onClick={() => setOpen(true)}
                  selector={flow(p.selector, (v) => v.payload.filters)}
                  dispatch={p.dispatch}
                />
              </FiltersWrapper>
            }
          >
            <ItemsTable
              selector={flow(data.selector, (v) => v.payload.items)}
              dispatch={p.dispatch}
            />
          </ListingWrapper>
          <AdvancedFilters
            isOpen={open}
            onClose={() => setOpen(false)}
            onApply={flow(Listing.submitFiltersAction, p.dispatch)}
            onClear={flow(Listing.resetFiltersAction, p.dispatch)}
          >
            <FiltersForm
              selector={flow(data.selector, (v) => v.payload.filters)}
              dispatch={p.dispatch}
            />
          </AdvancedFilters>
        </>
      );
    case "error":
      return <div>Error</div>;
  }
}

interface FiltersTriggerProps {
  onClick: () => void;
  selector: Selector<Listing.Ready["payload"]["filters"]>;
  dispatch: (a: Listing.Actions) => void;
}
function FiltersTrigger(p: FiltersTriggerProps) {
  const isSelected = useSelector(flow(p.selector, isFiltersEdited));

  return (
    <FiltersButton
      onClick={p.onClick}
      isSelected={isSelected}
      onClear={flow(Listing.resetFiltersAction, p.dispatch)}
    />
  );
}

interface FiltersFormProps {
  selector: Selector<Listing.Ready["payload"]["filters"]>;
  dispatch: (a: Listing.Actions) => void;
}
function FiltersForm(p: FiltersFormProps): ReactElement {
  const { t } = useTranslation();
  const store = useStore();

  return (
    <FormWrapper>
      <ItemSearchInput<"Stocks:Stock", unknown, { id: StockId }>
        label={t("By stock id")}
        prefix={"Stocks:Stock"}
        selector={flow(p.selector, (v) => v.id)}
        getId={(v) => v.id}
        getLabel={(v) => v.id as string as TranslatedStr}
        dispatch={p.dispatch}
      />
      <Field>
        <Toggle
          value$={flow(p.selector, (v) => v.latest)}
          onChange={(v) => p.dispatch(Listing.setLatestAtAction(v))}
        >
          {t("Latest only")}
        </Toggle>
      </Field>

      <Picker<boolean>
        value$={flow(p.selector, (v) => v.withItems)}
        onChange={flow(Listing.setWithItemsAction, p.dispatch)}
        options={[
          { value: true, label: t("With items") },
          { value: false, label: t("Without items") },
        ]}
      />
      <ItemSearchInput<"Stocks:InventoryItem", unknown, { id: InventoryItemId }>
        label={t("By inventory item")}
        prefix={"Stocks:InventoryItem"}
        selector={flow(p.selector, (v) => v.item)}
        getId={(v) => v.id}
        getLabel={(v) => v.id as string as TranslatedStr}
        dispatch={p.dispatch}
      />
      <ItemSearchInput<
        "Stocks:Repository",
        unknown,
        { id: RepositoryId; name: string }
      >
        label={t("By repository")}
        prefix={"Stocks:Repository"}
        selector={flow(p.selector, (v) => v.repository)}
        getId={(v) => v.id}
        getLabel={(v) => v.name as string as TranslatedStr}
        dispatch={p.dispatch}
      />
      <Field>
        <Label>{t("Quantity from")}</Label>
        <Number
          value$={flow(p.selector, (v) => v.quantity?.[0])}
          onChange={(v) => {
            const to = pipe(
              store.getState(),
              p.selector,
              (v) => v.quantity?.[1],
            );

            p.dispatch(Listing.setQuantityAction([v, to]));
          }}
          min={0}
        />
      </Field>
      <Field>
        <Label>{t("Quantity to")}</Label>
        <Number
          value$={flow(p.selector, (v) => v.quantity?.[1])}
          onChange={(v) => {
            const from = pipe(
              store.getState(),
              p.selector,
              (v) => v.quantity?.[0],
            );

            p.dispatch(Listing.setQuantityAction([from, v]));
          }}
          min={0}
        />
      </Field>
      <Field>
        <Label>{t("Created from")}</Label>
        <DatePickerInput
          value$={flow(p.selector, (v) => v.createdAt?.[0])}
          onChange={(v) => {
            const to = pipe(
              store.getState(),
              p.selector,
              (v) => v.createdAt?.[1],
            );

            p.dispatch(Listing.setCreatedAtAction([v, to]));
          }}
        />
      </Field>
      <Field>
        <Label>{t("Created to")}</Label>
        <DatePickerInput
          value$={flow(p.selector, (v) => v.createdAt?.[1])}
          onChange={(v) => {
            const from = pipe(
              store.getState(),
              p.selector,
              (v) => v.createdAt?.[0],
            );

            p.dispatch(Listing.setCreatedAtAction([from, v]));
          }}
        />
      </Field>
    </FormWrapper>
  );
}

function isFiltersEdited(
  filters: Listing.Ready["payload"]["filters"],
): boolean {
  return (
    ItemSearch.isSelected("Stocks:Stock")(filters.id) ||
    ItemSearch.isSelected("Stocks:InventoryItem")(filters.item) ||
    ItemSearch.isSelected("Stocks:Repository")(filters.repository) ||
    filters.withItems !== undefined ||
    filters.quantity !== undefined ||
    filters.createdAt !== undefined ||
    filters.search !== undefined
  );
}
