import { FieldId, FieldType, FieldValue } from "types/src/DataType/FieldType";
import { Valid } from "./types/State";

export function extractFieldsFromSchema(
  schema: Valid["payload"]["values"],
): Record<FieldId, FieldValue<FieldType>> {
  return Object.entries(schema).reduce(
    (acc, [key, value]) => {
      if (value.value === undefined) return acc;

      acc[key as FieldId] = value.value;
      return acc;
    },
    {} as Record<FieldId, FieldValue<FieldType>>,
  );
}
