import { silentUnreachableError } from "utils/exceptions";
import { keys } from "utils/object";
import { FieldId } from "types/src/DataType/FieldType";
import * as FormValue from "types/src/FormValue";
import { isNoEmptyString } from "types/src/NoEmptyString";
import * as O from "fp-ts/Option";
import { Repository } from "types/src/Repositories/Repository";
import * as SchemaFields from "../../../../../../../../generic-states/SchemaFields";
import * as Listing from "../Listing/types/State";
import * as ItemSearch from "../../../../../../../../generic-states/ItemSearch";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

const itemSearchPrefix = "Ready:DataManager:Repositories:Create:Parent";

const itemSearchReducer = ItemSearch.createReducer<
  typeof itemSearchPrefix,
  "unknown",
  Repository | undefined
>(itemSearchPrefix, (a, b) => a?.id === b?.id);

export function reducer(
  s: State.State,
  a: Actions.Actions,
): State.State | Listing.State {
  if (SchemaFields.isActions(a)) {
    if (State.isReady(s)) {
      return State.ready({
        ...s.payload,
        schema: SchemaFields.reducer(s.payload.schema, a),
      });
    }

    return s;
  }

  if (ItemSearch.isActions(itemSearchPrefix)(a)) {
    if (State.isReady(s)) {
      return State.ready({
        ...s.payload,
        parent: itemSearchReducer(s.payload.parent, a),
      });
    }

    return s;
  }

  switch (a.type) {
    case "Ready:DataManager:Repositories:Create:LoadFail": {
      if (State.isLoading(s)) {
        return State.loadError({
          dataTypeId: s.payload.dataTypeId,
        });
      }

      return s;
    }
    case "Ready:DataManager:Repositories:Create:LoadSuccess": {
      if (State.isLoading(s)) {
        return State.ready({
          name: FormValue.initial(""),
          type: FormValue.initial(undefined),
          dataTypeId: s.payload.dataTypeId,
          isVirtual: false,
          parent: ItemSearch.idle(itemSearchPrefix)({
            query: O.none,
            items: a.payload.repositories,
          }),
          schema: SchemaFields.init({
            fields: a.payload.fields,
            values: keys(a.payload.fields).reduce(
              (acc, key) => {
                acc[key] = FormValue.initial(undefined);
                return acc;
              },
              {} as Record<FieldId, FormValue.Initial<undefined>>,
            ),
          }),
        });
      }

      return s;
    }
    case "Ready:DataManager:Repositories:Create:SetName": {
      if (State.isReady(s) || State.isSubmitted(s)) {
        return State.ready({
          ...s.payload,
          name: isNoEmptyString(a.payload)
            ? FormValue.valid(a.payload)
            : FormValue.invalid("required" as const, a.payload),
        });
      }

      return s;
    }
    case "Ready:DataManager:Repositories:Create:SetType": {
      if (State.isReady(s) || State.isSubmitted(s)) {
        return State.ready({
          ...s.payload,
          type: FormValue.valid(a.payload),
        });
      }

      return s;
    }
    case "Ready:DataManager:Repositories:Create:SetIsVirtual": {
      if (State.isReady(s) || State.isSubmitted(s)) {
        return State.ready({
          ...s.payload,
          isVirtual: a.payload,
        });
      }

      return s;
    }
    case "Ready:DataManager:Repositories:Create:Submit": {
      if (State.isReady(s) || State.isSubmitted(s)) {
        const validated = SchemaFields.reducer(
          s.payload.schema,
          SchemaFields.validate(),
        );

        const name = isNoEmptyString(s.payload.name.value)
          ? FormValue.valid(s.payload.name.value)
          : FormValue.invalid("required" as const, s.payload.name.value);
        const type = s.payload.type.value
          ? FormValue.valid(s.payload.type.value)
          : FormValue.invalid("required" as const, s.payload.type.value);

        if (
          SchemaFields.isValid(validated) &&
          FormValue.isValid(name) &&
          FormValue.isValid(type)
        ) {
          return State.saving({
            name: name,
            type: type,
            dataTypeId: s.payload.dataTypeId,
            schema: validated,
            isVirtual: s.payload.isVirtual,
            parent: s.payload.parent,
          });
        } else {
          return State.submitted({
            type: type,
            name: name,
            dataTypeId: s.payload.dataTypeId,
            schema: validated,
            isVirtual: s.payload.isVirtual,
            parent: s.payload.parent,
          });
        }
      }

      return s;
    }
    case "Ready:DataManager:Repositories:Create:SaveError": {
      if (State.isSaving(s)) {
        return State.ready(s.payload);
      }

      return s;
    }
    case "Ready:DataManager:Repositories:Create:SaveSuccess": {
      if (State.isSaving(s)) {
        return Listing.init(s.payload.dataTypeId);
      }

      return s;
    }
    default: {
      silentUnreachableError(a);
      return s;
    }
  }
}
