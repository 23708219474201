import * as ItemSearch from "state-manager/generic-states/ItemSearch";
import { ReactElement } from "react";
import { SearchCombobox, SelectorProps } from "@Containers/Form/SearchCombobox";
import { Selector } from "state-manager";
import { flow } from "fp-ts/function";
import { TranslatedStr } from "types/src/TranslatedStr";
import * as O from "fp-ts/Option";

export interface ItemSearchInputProps<P extends string, E, T> {
  label: TranslatedStr;
  prefix: P;
  selector: Selector<ItemSearch.State<P, E, T>>;
  dispatch: (a: ItemSearch.Actions<P, E, T>) => void;
  getLabel: (v: T) => TranslatedStr;
  getId: (v: T) => string;
}

export function ItemSearchInput<P extends string, E, T>(
  p: ItemSearchInputProps<P, E, T>,
): ReactElement {
  const selector = flow(p.selector, (v): SelectorProps<T> => {
    return {
      validation: ItemSearch.isItemSearchError(p.prefix)(v)
        ? "error"
        : undefined,
      value: ItemSearch.getValue(p.prefix, v),
      options: v.payload.items.map((i) => ({
        value: i,
        label: p.getLabel(i),
      })),
      isLoading: ItemSearch.isSearching(p.prefix)(v),
    };
  });

  return (
    <SearchCombobox
      label={p.label}
      onChange={flow(
        O.map(ItemSearch.selectItem(p.prefix)),
        O.getOrElseW(() => ItemSearch.clear(p.prefix)),
        p.dispatch,
      )}
      onSearch={flow(ItemSearch.setQuery(p.prefix), p.dispatch)}
      selector={selector}
      getId={p.getId}
    />
  );
}
