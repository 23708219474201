import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  from,
  map,
  merge,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError } from "ds";
import { flow, identity } from "fp-ts/function";
import { createSupplier } from "ds/Suppliers";
import { getDataType } from "ds/DataTypes";
import * as E from "fp-ts/Either";
import { extractFieldsFromSchema } from "../../../../../../../../generic-states/SchemaFields/utils";
import * as SchemaFields from "../../../../../../../../generic-states/SchemaFields";
import { Epic } from "../../../../../../../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

export const epic: Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
> = (state$, { pyckAdminClient$ }) => {
  const fieldsSchema$ = SchemaFields.epic(
    state$.pipe(
      filter(State.isLoaded),
      map((s) => s.payload.schema),
    ),
    pyckAdminClient$,
  );

  const loading$ = state$.pipe(
    filter(State.isLoading),
    map((s) => s.payload.dataTypeId),
    distinctUntilChanged(),
    withLatestFrom(pyckAdminClient$),
    switchMap(([dataTypeId, client]) =>
      from(getDataType(client, dataTypeId)).pipe(
        map(
          flow(
            E.mapLeft(Actions.loadFail),
            E.chain(E.fromNullable(Actions.loadFail())),
            E.filterOrElse((v) => v.entity === "supplier", Actions.loadFail),
            E.map((v) => v.schema),
            E.map(Actions.loadSuccess),
            E.getOrElse<Actions.Actions, Actions.Actions>(identity),
          ),
        ),
      ),
    ),
  );

  const create$ = state$.pipe(
    distinctUntilKeyChanged("type"),
    filter(State.isSaving),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) =>
      from(
        createSupplier(client, {
          dataTypeId: s.dataTypeId,
          fields: extractFieldsFromSchema(s.schema.payload.values),
        }),
      ).pipe(
        map(E.map(Actions.saveSuccess)),
        map(E.getOrElse<DsError, Actions.Actions>(Actions.saveError)),
        catchError(() => of(Actions.saveError())),
      ),
    ),
  );

  return merge(loading$, create$, fieldsSchema$);
};
