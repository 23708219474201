import { ReactNode } from "react";
import styled from "styled-components";
import { GearIcon } from "icons/Gear";
import { TrashIcon } from "icons/Trash";

export interface PreviewProps {
  children: ReactNode;
  openSettings: () => void;
  onRemove: () => void;
  isActive?: boolean;
}

export function Preview({
  children,
  openSettings,
  isActive,
  onRemove,
}: PreviewProps) {
  return (
    <Wrapper aria-activedescendant={isActive ? "true" : undefined}>
      <Disabled onClick={openSettings}>{children}</Disabled>
      <SettingsWrapper>
        <Settings onClick={openSettings}>
          <GearIcon />
        </Settings>
        <Settings onClick={onRemove}>
          <TrashIcon />
        </Settings>
      </SettingsWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
`;

const Disabled = styled.div`
  flex: 1;
  cursor: pointer;

  & > * {
    pointer-events: none;
  }
`;

const SettingsWrapper = styled.div`
  padding-left: ${(p) => p.theme.space.sm};
  margin-right: calc(calc(${(p) => p.theme.space.sm} + 42px) * -1);
  display: flex;
  gap: 10px;
`;

const Settings = styled.button`
  padding: 0;
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  color: ${(p) => p.theme.palette.grey[600]};

  ${Wrapper}:hover & {
    opacity: 0.5;
  }

  ${Wrapper}:hover &:hover, ${Wrapper}[aria-activedescendant="true"] & {
    opacity: 1;
  }
`;
