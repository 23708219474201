import { InventoryItem } from "types/src/InventoryItems/InventoryItem";
import { toDate } from "types/src/date/ISODate";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { InventoryItemFragmentFragment } from "../generated/graphql";

export function inventoryItemFragmentToInventoryItem(
  fragment: InventoryItemFragmentFragment,
): InventoryItem {
  return {
    id: fragment.id as InventoryItem["id"],
    fields: (fragment.inventoryJSONData ?? { fields: {} })
      .fields as InventoryItem["fields"],
    createdAt: toDate(fragment.createdAt),
    updatedAt: pipe(
      fragment.updatedAt,
      O.fromNullable,
      O.map(toDate),
      O.getOrElseW(() => undefined),
    ),
    dataTypeId: fragment.dataTypeID as InventoryItem["dataTypeId"],
    sku: fragment.sku as InventoryItem["sku"],
  };
}
