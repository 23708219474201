import { silentUnreachableError } from "utils/exceptions";
import { FieldId, FieldType } from "types/src/DataType/FieldType";
import { Field } from "types/src/DataType/Field";
import { strictGuard } from "utils/strictGuard";
import { EditedValue, InitialValue, ValidValue } from "./Value";

// region State
export type State = Init | Edited | Valid;

export const isState = strictGuard((s: State): s is State => {
  const type = s.type;

  switch (type) {
    case "SchemaFields:Init":
    case "SchemaFields:Edited":
    case "SchemaFields:Valid":
      return true;
    default:
      silentUnreachableError(type);
      return false;
  }
});
// endregion

// region Init
export interface InitPayload {
  fields: Record<FieldId, Field<FieldType>>;
  values: InitialValue;
}

export interface Init {
  type: "SchemaFields:Init";
  payload: InitPayload;
}

export const init = (payload: Init["payload"]): Init => ({
  type: "SchemaFields:Init",
  payload,
});

export const isInit = (a: State): a is Init => a.type === "SchemaFields:Init";
// endregion

// region Edited
export interface EditedPayload extends Omit<InitPayload, "values"> {
  values: EditedValue;
}

export interface Edited {
  type: "SchemaFields:Edited";
  payload: EditedPayload;
}

export const edited = (payload: Edited["payload"]): Edited => ({
  type: "SchemaFields:Edited",
  payload,
});

export const isEdited = (a: State): a is Edited =>
  a.type === "SchemaFields:Edited";
// endregion

// region Valid
export interface ValidPayload extends EditedPayload {
  values: ValidValue;
}

export interface Valid {
  type: "SchemaFields:Valid";
  payload: ValidPayload;
}

export const valid = (payload: Valid["payload"]): Valid => ({
  type: "SchemaFields:Valid",
  payload,
});

export const isValid = (a: State): a is Valid =>
  a.type === "SchemaFields:Valid";
// endregion
