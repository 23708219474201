import { FieldList } from "ui/layouts/Schema/FielsList";
import { FieldCard } from "ui/layouts/Schema/FieldCard";
import * as SchemaState from "state-manager/generic-states/Schema";
import { Button } from "ui/components/Button";
import { useTranslation } from "i18n";
import { useDispatch } from "state-manager";
import { useState } from "react";
import { FieldType } from "types/src/DataType/FieldType";
import { NumberIcon } from "../../../../../packages/icons/src/Number";
import { TextIcon } from "../../../../../packages/icons/src/Text";

export function Add() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleAdd = (type: FieldType) => {
    dispatch(SchemaState.add(type));
    setShow(false);
  };

  return (
    <>
      {show && (
        <FieldList>
          <FieldCard
            name={t("Text")}
            icon={<TextIcon />}
            onClick={() => handleAdd("text")}
          />
          <FieldCard
            name={t("Number")}
            icon={<NumberIcon />}
            onClick={() => handleAdd("number")}
          />
        </FieldList>
      )}
      <div>
        <Button isStretched onClick={() => setShow(!show)} isPrimary>
          {t("Add Custom Field")}
        </Button>
      </div>
    </>
  );
}
