import { RootState, useSelector } from "state-manager";
import {
  FieldId,
  FieldType,
  FieldValue,
  FieldValueError,
} from "types/src/DataType/FieldType";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { flow } from "fp-ts/function";
import { ReactElement } from "react";
import { shallowEqualArrays } from "shallow-equal";
import * as FormValue from "types/src/FormValue";
import { Field } from "types/src/DataType/Field";
import { SchemaField } from "@Containers/SchemaFields/SchemaField";

export interface SchemaFieldsProps {
  selector: (state: RootState) => {
    fields: Record<FieldId, Field<FieldType>>;
    values: Record<
      FieldId,
      FormValue.Value<
        FieldValueError<FieldType>,
        FieldValue<FieldType> | undefined,
        FieldValue<FieldType> | undefined
      >
    >;
  };
  onChange: <T extends FieldType>(p: {
    id: FieldId;
    type: T;
    value: FieldValue<T> | undefined;
  }) => void;
}

export function SchemaFields({
  selector,
  onChange,
}: SchemaFieldsProps): ReactElement {
  const ids = useSelector(
    flow(selector, ({ fields }) => {
      const t = Object.values(fields).map(({ id, order }) => ({
        id,
        order,
      }));

      t.sort((a, b) => a.order - b.order);

      return t.map(({ id }) => id);
    }),
    shallowEqualArrays,
  );

  return (
    <FormWrapper>
      {ids.map((id) => (
        <SchemaField
          key={id}
          field$={flow(selector, (s) => s.fields[id]!)}
          value$={flow(selector, (s) => s.values[id]!)}
          onChange={({ type, value }) => onChange({ id, type, value })}
        />
      ))}
    </FormWrapper>
  );
}
