import { silentUnreachableError } from "utils/exceptions";
import { FieldId, FieldType } from "types/src/DataType/FieldType";
import { Field } from "types/src/DataType/Field";
import { Repository, RepositoryId } from "types/src/Repositories/Repository";
import { RepositoryType } from "types/src/Repositories/RepositoryType";
import { strictGuard } from "utils/strictGuard";
import * as SchemaFields from "../../../../../../../../../generic-states/SchemaFields";
import * as ItemSearch from "../../../../../../../../../generic-states/ItemSearch/types/Actions";

// region Actions
export type Actions =
  | LoadSuccess
  | LoadFail
  | Submit
  | SaveError
  | SaveSuccess
  | SetName
  | SetType
  | SetIsVirtual
  | SchemaFields.Actions
  | ItemSearch.Actions<
      "Ready:DataManager:Repositories:Create:Parent",
      "unknown",
      Repository | undefined
    >;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (SchemaFields.isActions(a)) return true;
  if (ItemSearch.isActions("Ready:DataManager:Repositories:Create:Parent")(a))
    return true;

  switch (a.type) {
    case "Ready:DataManager:Repositories:Create:LoadFail":
    case "Ready:DataManager:Repositories:Create:LoadSuccess":
    case "Ready:DataManager:Repositories:Create:SetName":
    case "Ready:DataManager:Repositories:Create:SetType":
    case "Ready:DataManager:Repositories:Create:SaveError":
    case "Ready:DataManager:Repositories:Create:SaveSuccess":
    case "Ready:DataManager:Repositories:Create:Submit":
    case "Ready:DataManager:Repositories:Create:SetIsVirtual":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:Repositories:Create:LoadFail";
}

export const loadFail = (): LoadFail => ({
  type: "Ready:DataManager:Repositories:Create:LoadFail",
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:Repositories:Create:LoadSuccess";
  payload: {
    fields: Record<FieldId, Field<FieldType>>;
    repositories: Repository[];
  };
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:Repositories:Create:LoadSuccess",
  payload,
});
// endregion

// region SetName
export interface SetName {
  type: "Ready:DataManager:Repositories:Create:SetName";
  payload: string;
}

export const setName = (payload: SetName["payload"]): SetName => ({
  type: "Ready:DataManager:Repositories:Create:SetName",
  payload,
});
// endregion

// region SetType
export interface SetType {
  type: "Ready:DataManager:Repositories:Create:SetType";
  payload: RepositoryType;
}

export const setType = (payload: SetType["payload"]): SetType => ({
  type: "Ready:DataManager:Repositories:Create:SetType",
  payload,
});
// endregion

// region SetIsVirtual
export interface SetIsVirtual {
  type: "Ready:DataManager:Repositories:Create:SetIsVirtual";
  payload: boolean;
}

export const setIsVirtual = (
  payload: SetIsVirtual["payload"],
): SetIsVirtual => ({
  type: "Ready:DataManager:Repositories:Create:SetIsVirtual",
  payload,
});
// endregion

// region SetParent
export interface SetParent {
  type: "Ready:DataManager:Repositories:Create:SetParent";
  payload: RepositoryId | undefined;
}

export const setParent = (payload: SetParent["payload"]): SetParent => ({
  type: "Ready:DataManager:Repositories:Create:SetParent",
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: "Ready:DataManager:Repositories:Create:Submit";
}

export const submit = (): Submit => ({
  type: "Ready:DataManager:Repositories:Create:Submit",
});
// endregion

// region SaveError
export interface SaveError {
  type: "Ready:DataManager:Repositories:Create:SaveError";
}

export const saveError = (): SaveError => ({
  type: "Ready:DataManager:Repositories:Create:SaveError",
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: "Ready:DataManager:Repositories:Create:SaveSuccess";
  payload: Repository;
}

export const saveSuccess = (payload: SaveSuccess["payload"]): SaveSuccess => ({
  type: "Ready:DataManager:Repositories:Create:SaveSuccess",
  payload,
});
// endregion
