import { v4 as uuidv4 } from "uuid";
import { NoEmptyString } from "../NoEmptyString";

// region FieldId
declare const _fieldId: unique symbol;

export type FieldId = string & { [_fieldId]: "FieldId" };

export const getUniqueId = (): FieldId => uuidv4() as FieldId;
// endregion

export type FieldType = "text" | "number";
export const fieldTypes: FieldType[] = Object.values({
  text: "text",
  number: "number",
} satisfies { [k in FieldType]: k });

export type FieldValue<T extends FieldType> = T extends FieldType
  ? {
      text: NoEmptyString;
      number: number;
    }[T]
  : never;

export type FieldConfig<T extends FieldType> = {
  text: {
    placeholder: string | undefined;
    minLength: number | undefined;
    maxLength: number | undefined;
  };
  number: {
    placeholder: string | undefined;
    min: number | undefined;
    max: number | undefined;
    step: number | undefined;
  };
}[T];

type FieldValueErrors<T extends FieldType> = T extends FieldType
  ? {
      text: {
        type: "minLength" | "maxLength" | "exactLength";
        value: number;
      };
      number: {
        type: "min" | "max";
        value: number;
      };
    }[T]
  : never;

export type FieldValueError<T extends FieldType> =
  | { type: "required" }
  | FieldValueErrors<T>;
