import { Customer } from "types/src/Customers/Customer";
import { toDate } from "types/src/date/ISODate";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { CustomerFragmentFragment } from "../generated/graphql";

export function customerFragmentToCustomer(
  customerFragment: CustomerFragmentFragment,
): Customer {
  return {
    id: customerFragment.id as Customer["id"],
    fields: (customerFragment.data ?? { fields: {} })
      .fields as Customer["fields"],
    createdAt: toDate(customerFragment.createdAt),
    updatedAt: pipe(
      customerFragment.updatedAt,
      O.fromNullable,
      O.map(toDate),
      O.getOrElseW(() => undefined),
    ),
    deletedAt: pipe(
      customerFragment.updatedAt,
      O.fromNullable,
      O.map(toDate),
      O.getOrElseW(() => undefined),
    ),
    dataTypeId: customerFragment.dataTypeID as Customer["dataTypeId"],
  };
}
