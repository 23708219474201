import { memo, ReactElement, useCallback, useMemo } from "react";
import { RootState, useSelector } from "state-manager";
import * as SchemaState from "state-manager/generic-states/Schema";
import { FieldConfig, FieldId, FieldType } from "types/src/DataType/FieldType";
import { shallowEqualArrays } from "shallow-equal";
import { Field as FieldDef } from "types/src/DataType/Field";
import { flow } from "fp-ts/function";
import { Field, FieldProps } from "./Field";

interface Dispatch {
  remove: (id: FieldId) => void;
  setConfig: (payload: { id: FieldId; value: FieldConfig<FieldType> }) => void;
}

export interface CustomFieldsProps {
  selector: (state: RootState) => SchemaState.State;
  dispatch: Dispatch;
}

export function CustomFields({
  selector,
  dispatch,
}: CustomFieldsProps): ReactElement {
  const ids = useSelector(flow(selector, selectIds), shallowEqualArrays);
  const fieldSelector = useCallback(
    (id: FieldId) =>
      flow(selector, (s) => s.payload.fields[id] as FieldDef<FieldType>),
    [selector],
  );

  return (
    <>
      {ids.map((id) => (
        <FieldById
          key={id}
          id={id}
          selector={fieldSelector}
          dispatch={dispatch}
        />
      ))}
    </>
  );
}

interface FieldByIdProps {
  id: FieldId;
  selector: (id: FieldId) => FieldProps["selector"];
  dispatch: Dispatch;
}
const FieldById = memo(({ id, selector, dispatch }: FieldByIdProps) => {
  const fieldSelector = useMemo(() => selector(id), [id, selector]);
  const fieldDispatch = useMemo<FieldProps["dispatch"]>(
    () => ({
      remove: () => dispatch.remove(id),
      setConfig: (value) => dispatch.setConfig({ id, value }),
    }),
    [dispatch],
  );

  return <Field selector={fieldSelector} dispatch={fieldDispatch} />;
});

const selectIds = (state: SchemaState.State): FieldId[] =>
  Object.values(state.payload.fields)
    .sort((a, b) => (a.order <= b.order ? -1 : 1))
    .map((v) => v.id);
