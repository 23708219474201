import { ComponentProps, ReactElement } from "react";
import styled from "styled-components";
import { TypographySM } from "@components/Typography";

export interface FieldCardProps extends ComponentProps<"div"> {
  name: string;
  icon: ReactElement;
}

export function FieldCard({
  name,
  icon,
  ...props
}: FieldCardProps): ReactElement {
  return (
    <Wrapper {...props}>
      {icon}
      <TypographySM>{name}</TypographySM>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 3px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.palette.grey[200]};
  border: 1px solid ${(p) => p.theme.colors.chromeHue};
`;
