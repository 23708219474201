import {
  distinctUntilKeyChanged,
  filter,
  forkJoin,
  from,
  map,
  merge,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError, notFoundError, QueryResponse } from "ds";
import {
  getRepositories,
  getRepository,
  updateRepository,
} from "ds/Repositories";
import { getDataType } from "ds/DataTypes";
import { flow, pipe } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { Repository } from "types/src/Repositories/Repository";
import { sequenceT } from "fp-ts/Apply";
import * as SchemaFields from "../../../../../../../../generic-states/SchemaFields";
import { extractFieldsFromSchema } from "../../../../../../../../generic-states/SchemaFields/utils";
import { Epic } from "../../../../../../../../types/RootEpic";
import { isSelected } from "../../../../../../../../generic-states/ItemSearch";
import * as ItemSearch from "../../../../../../../../generic-states/ItemSearch";
import { dsErrorNotification } from "../../../../../../../Notifications/epic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

const itemSearchEpic = ItemSearch.epicCreator<
  "Ready:DataManager:Repositories:Edit:Parent",
  "unknown",
  Repository | undefined
>("Ready:DataManager:Repositories:Edit:Parent");

export const epic: Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
> = (state$, { pyckAdminClient$ }) => {
  const fieldsSchema$ = SchemaFields.epic(
    state$.pipe(
      filter(State.isLoaded),
      map((s) => s.payload.schema),
    ),
    pyckAdminClient$,
  );

  const loading$ = state$.pipe(
    filter(State.isLoading),
    map((s) => s.payload.id),
    withLatestFrom(pyckAdminClient$),
    switchMap(([id, client]) => {
      return from(getRepository(client, id)).pipe(
        switchMap((v) => {
          return pipe(
            v,
            E.map((item) => {
              const fields$ = from(getDataType(client, item.dataTypeId)).pipe(
                map(E.map((dt) => dt.schema.fields)),
              );
              const parent$: Observable<QueryResponse<Repository | undefined>> =
                item.parentId
                  ? from(getRepository(client, item.parentId))
                  : of(E.right<DsError, undefined>(undefined));
              const repositories$ = from(getRepositories(client, {})).pipe(
                map(E.map((r) => r.items)),
              );
              const repositoriesWithParent$ = forkJoin({
                repositories: repositories$,
                parent: parent$,
              }).pipe(
                map(({ repositories, parent }) => {
                  return pipe(
                    parent,
                    E.filterOrElseW((v): v is Repository => !!v, notFoundError),
                    E.chain((r) =>
                      E.map<Repository[], Repository[]>((rs) => [r, ...rs])(
                        repositories,
                      ),
                    ),
                    E.chain(() => repositories),
                  );
                }),
              );

              return forkJoin({
                fields: fields$,
                repositories: repositoriesWithParent$,
                item: of(E.right(item)),
              }).pipe(
                map(({ fields, repositories, item }) =>
                  sequenceT(E.Apply)(fields, repositories, item),
                ),
                map(
                  E.map(([fields, repositories, item]) => ({
                    fields,
                    repositories,
                    item,
                  })),
                ),
              );
            }),
            E.getOrElseW((e) => of(E.left(e))),
          );
        }),
        map(
          flow(
            E.map(Actions.loadSuccess),
            E.getOrElse<DsError, Actions.Actions>(Actions.loadFail),
          ),
        ),
      );
    }),
  );

  const update$ = state$.pipe(
    distinctUntilKeyChanged("type"),
    filter(State.isSaving),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) =>
      from(
        updateRepository(client, {
          id: s.id,
          name: s.name.value,
          type: s.type.value,
          dataTypeId: s.dataTypeId,
          fields: extractFieldsFromSchema(s.schema.payload.values),
          parent: isSelected("Ready:DataManager:Repositories:Edit:Parent")(
            s.parent,
          )
            ? s.parent.payload.item?.id
            : undefined,
        }),
      ).pipe(
        dsErrorNotification(
          flow(E.map(Actions.saveSuccess), E.getOrElseW(Actions.saveError)),
        ),
      ),
    ),
  );

  const parentSearch$ = pyckAdminClient$.pipe(
    switchMap((client) =>
      itemSearchEpic(
        state$.pipe(
          filter(State.isReady),
          map((s) => s.payload.parent),
        ),
        {
          get: (q) =>
            getRepositories(client, { where: { name: q } }).then(
              flow(
                E.map((r) => r.items),
                E.mapLeft(() => "unknown"),
              ),
            ),
        },
      ),
    ),
  );

  return merge(loading$, update$, fieldsSchema$, parentSearch$);
};
